<template>
    <Dialog v-model:visible="dialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true" style="width: 90rem">
        <template #header>
            <div class="flex align-items-center">
                <Avatar :image="Utils.GetGenericAvatar(state.id)" class="flex align-items-center border-1 border-300 mt-0" size="medium" shape="circle" />
                <h4 class="flex align-items-center ml-2 mt-3">{{ headerTitle }}</h4>
            </div>
        </template>

        <TabMenu v-if="showTab" :model="tabItems" class="mb-4 fixed-tab-menu" />
        <div v-if="tabIndex === 0" class="flex flex-column gap-4">
            <div class="flex flex-row w-full">
                <label>Τελευταίο Login:</label>
                <span style="padding-left: 10px">{{ lastLogin || '--' }}</span>
            </div>
            <div class="flex flex-row flex-wrap box p-4 justify-content-between gap-4">
                <div v-if="isAdminOrOffice" class="field-checkbox w-full">
                    <Checkbox id="active" v-model="state.active" :binary="true" :disabled="!isAdminOrOffice" />
                    <label for="active">Ενεργός</label>
                </div>
                <div class="flex flex-row justify-content-between w-full gap-4">
                    <div class="flex flex-column w-20rem">
                        <label for="fname" :class="{ 'p-error': v$.fname.$invalid && submitted }">Όνομα*</label>
                        <InputText
                            id="fname"
                            v-model="v$.fname.$model"
                            :disabled="!isAdminOrOffice"
                            autocomplete="off"
                            spellcheck="off"
                            :class="{ 'p-invalid': v$.fname.$invalid && submitted }"
                            placeholder="Όνομα"
                        />
                        <small v-if="(v$.fname.$invalid && submitted) || v$.fname.$pending.$response" class="p-error">{{ v$.fname.required.$message }}</small>
                    </div>

                    <div class="flex flex-column w-20rem">
                        <label for="lname" :class="{ 'p-error': v$.lname.$invalid && submitted }">Επώνυμο*</label>
                        <InputText
                            id="lname"
                            v-model="v$.lname.$model"
                            :disabled="!isAdminOrOffice"
                            autocomplete="off"
                            spellcheck="off"
                            :class="{ 'p-invalid': v$.lname.$invalid && submitted }"
                            placeholder="Επώνυμο"
                        />
                        <small v-if="(v$.lname.$invalid && submitted) || v$.lname.$pending.$response" class="p-error">{{ v$.lname.required.$message }}</small>
                    </div>

                    <div class="flex flex-column w-15rem">
                        <label for="mobile" :class="{ 'p-error': v$.mobile.$invalid && submitted }">Κινητό*</label>
                        <InputText
                            id="mobile"
                            v-model="v$.mobile.$model"
                            :disabled="!isAdminOrOffice"
                            autocomplete="off"
                            spellcheck="off"
                            :class="{ 'p-invalid': v$.mobile.$invalid && submitted }"
                            placeholder="Κινητό"
                        />
                        <small v-if="(v$.mobile.$invalid && submitted) || v$.mobile.$pending.$response" class="p-error">{{ v$.mobile.required.$message }}</small>
                    </div>

                    <div class="flex flex-column w-20rem">
                        <label for="email" :class="{ 'p-error': v$.email.$invalid && submitted }">Email*</label>
                        <InputText
                            id="email"
                            v-model="v$.email.$model"
                            :disabled="!isAdminOrOffice"
                            autocomplete="off"
                            spellcheck="off"
                            :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                            placeholder="Email"
                        />
                        <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{ v$.email.required.$message }}</small>
                    </div>
                </div>

                <div class="flex flex-column w-full">
                    <label for="department">Ειδικότητες</label>
                    <MultiSelect id="department" v-model="state.departments" :options="departments" optionLabel="name" :disabled="!isAdminOrOffice" placeholder="Επιλέξτε ειδικότητες" />
                </div>

                <div class="flex flex-column w-full">
                    <label for="courses">Μαθήματα</label>
                    <Textarea v-model="state.courses" autocomplete="off" spellcheck="off" rows="30" cols="30" placeholder="Ελεύθερο κείμενο" :disabled="!state.departments" />
                </div>
            </div>
        </div>
        <div v-if="tabIndex === 1" class="flex flex-column gap-4">
            <div class="flex flex-column flex-wrap box p-4 justify-content-between gap-4">
                <h5>Στοιχεία</h5>
                <div class="flex flex-row flex-wrap justify-content-between gap-4">
                    <div class="flex flex-column w-15rem">
                        <label>Όνομα Πατρός</label>
                        <InputText v-model="state.fatherName" autocomplete="off" spellcheck="off" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label>Όνομα Μητρός</label>
                        <InputText v-model="state.motherName" autocomplete="off" spellcheck="off" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label>Φύλο</label>
                        <Dropdown v-model="state.gender" :options="Utils.Genders" option-label="label" placeholder="Φύλο"></Dropdown>
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="birthday">Ημ/νία γέννησης</label>
                        <Calendar id="birthday" v-model="state.birthDate" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label>Επίπεδο Εκπαίδευσης</label>
                        <Dropdown v-model="state.educationLevel" :options="Utils.EducationLevelOptions" option-label="label" placeholder="Επιλέξτε"></Dropdown>
                    </div>
                    <div class="flex flex-column gap-4">
                        <div class="flex flex-column w-15rem">
                            <label>Οικογενειακή κατάσταση</label>
                            <Dropdown v-model="state.familyStatus" :options="Utils.FamilyStatusOptions" option-label="label" placeholder="Επιλέξτε"></Dropdown>
                        </div>
                        <div class="flex flex-column w-10rem">
                            <label>Αριθμός τέκνων</label>
                            <Dropdown v-model="state.children" :options="Utils.ChildrenChoices" option-label="label" placeholder="Επιλέξτε"></Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-column flex-wrap box p-4 justify-content-between gap-4">
                <h5>Στοιχεία κατοικίας</h5>
                <div class="flex flex-row flex-wrap justify-content-between gap-4">
                    <div class="flex flex-column w-15rem">
                        <label for="city">Πόλη</label>
                        <InputText id="city" v-model="state.city" autocomplete="off" spellcheck="off" placeholder="Πόλη" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="address">Οδός/Αριθμός</label>
                        <InputText id="address" v-model="state.address" autocomplete="off" spellcheck="off" placeholder="Διεύθυνση" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="zipcode">Τ.Κ.</label>
                        <InputText id="zipcode" v-model="state.zipcode" autocomplete="off" spellcheck="off" placeholder="Τ.Κ." />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="phone">Σταθερό τηλέφωνο</label>
                        <InputText id="phone" v-model="state.phone" autocomplete="off" spellcheck="off" placeholder="Σταθερό" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column flex-wrap box p-4 justify-content-between gap-4">
                <h5>Φορολογικα - Ασφαλιστικα Στοιχεια</h5>
                <div class="flex flex-row flex-wrap justify-content-between gap-4">
                    <div class="flex flex-column w-15rem">
                        <label for="afm">Αρ. Μητρώου ΙΚΑ</label>
                        <InputText id="afm" v-model="state.ikaNumber" autocomplete="off" spellcheck="off" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="afm">ΑΜΚΑ</label>
                        <InputText id="afm" v-model="state.amka" autocomplete="off" spellcheck="off" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="afm">ΑΦΜ</label>
                        <InputText id="afm" v-model="state.afm" autocomplete="off" spellcheck="off" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="afm">ΔΟΥ</label>
                        <InputText id="afm" v-model="state.doy" autocomplete="off" spellcheck="off" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="afm">Αρ. Δελτίου Ταυτότητας</label>
                        <InputText id="afm" v-model="state.passportID" autocomplete="off" spellcheck="off" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="birthday">Ημερομηνία Έκδοσης</label>
                        <Calendar id="birthday" v-model="state.passportDate" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" />
                    </div>
                    <div class="flex flex-column w-15rem">
                        <label for="afm">Εκδούσα Αρχή</label>
                        <InputText id="afm" v-model="state.passportOrigin" autocomplete="off" spellcheck="off" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column flex-wrap box p-4 justify-content-between gap-4">
                <h5>Στοιχεία τράπεζας</h5>
                <div class="flex flex-row flex-wrap justify-content-between gap-4">
                    <div class="flex flex-column w-25rem">
                        <label for="bank">Τράπεζα</label>
                        <Dropdown v-model="state.bank" :options="Utils.BankChoiceOptions" option-label="label" placeholder="Επιλέξτε"></Dropdown>
                    </div>
                    <div class="flex flex-column w-25rem">
                        <label for="iban">IBAN</label>
                        <InputText id="iban" v-model="state.iban" autocomplete="off" spellcheck="off" placeholder="IBAN" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="tabIndex === 2" class="flex flex-column gap-4">
            <div class="flex flex-column flex-wrap box p-4 justify-content-between gap-4">
                <div class="flex flex-row flex-wrap justify-content-between gap-4">
                    <div class="flex flex-row justify-content-between gap-4">
                        <div v-if="isAdmin" class="flex flex-column">
                            <label>Επιλογή συνεργασίας</label>
                            <Dropdown v-model="state.cooperationType" :options="Utils.CooperationTypesOptions" option-label="label" placeholder="Επιλέξτε"></Dropdown>
                        </div>
                        <div v-if="isAdminOrOffice" class="flex flex-column">
                            <label>Αρχεία</label>
                            <Button label="Αρχεία" class="p-button-help w-10rem" icon="pi pi-upload" @click="onShowBioFilesPopup" />
                        </div>
                    </div>
                    <div class="flex flex-column w-full">
                        <label>Σχόλια</label>
                        <div class="flex flex-column" style="max-width: 65rem">
                            <SyncfusionRTE v-model="state.comments" />
                        </div>
                    </div>
                    <div v-if="isAdmin" class="flex flex-column w-full">
                        <label>Σχόλια admin</label>
                        <div class="flex flex-column" style="max-width: 65rem">
                            <SyncfusionRTE v-model="state.adminComments" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="tabIndex === 3"><Todos ref="todos" :type="Utils.TodoType.Teacher" :typeRefererId="state.id" /></div>
        <div v-if="tabIndex === 4"><Chat ref="chat" chatH="60vh" :priveOnlyUser="chatUserInfo" /></div>
        <div v-if="tabIndex === 5">
            <TeacherNotifications
                ref="teacherNotifications"
                @add-notification="addTeacherNotification"
                @show-notification="showTeacherNotification"
                @edit-notification="editTeacherNotification"
                @clone-notification="cloneTeacherNotification"
                @delete-notification="deleteTeacherNotification"
            />
        </div>
        <template v-if="(tabIndex === 0 || tabIndex === 1 || tabIndex === 2) && (isAdminOrOffice || isTeacher)" #footer>
            <div class="flex flex-row justify-content-between mt-4">
                <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
                <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
            </div>
        </template>
    </Dialog>
    <Dialog v-model:visible="showUploaderDialog" modal>
        <template #header>
            <h3>{{ uploaderTitle }}</h3>
        </template>
        <Uploader ref="uploader" />
    </Dialog>
</template>

<script setup>
import Todos from '@/components/Todos.vue';
import TeacherEconomics from '@/components/TeacherEconomics.vue';
import TeacherNotifications from '@/components/TeacherNotifications.vue';
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, watch, nextTick } from 'vue';
import { required, integer, email } from '@vuelidate/validators';
import { usePopupStore } from '@/stores/PopupStore';
import moment from 'moment-timezone';
import { useConfirm } from 'primevue/useconfirm';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { FilterMatchMode } from 'primevue/api';
import { useDebounceFn } from '@vueuse/core';
import Uploader from '@/components/Uploader.vue';
import Chat from '@/components/Chat.vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import QuillEditor from '@/components/QuillEditor.vue';
import SyncfusionRTE from '@/components/SyncfusionRTE.vue';

const globalStore = useGlobalStore();
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const confirm = useConfirm();
const submitted = ref(false);
const headerTitle = ref('');
const categories = ref([]);
const fetchClsParams = ref({});
const fetchReqParams = ref({});
const teacherEconomics = ref(null);
const teacherNotifications = ref(null);
const popupStore = usePopupStore();
const departments = ref(null);
const allCourses = ref(null);
const lastLogin = ref(null);
const showUploaderDialog = ref(false);
const uploader = ref(null);
const uploaderTitle = ref('');
const chat = ref(null);

const isAdmin = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles?.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo && Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const state = reactive({
    id: null,
    fname: '',
    lname: '',
    email: '',
    mobile: '',
    phone: '',
    afm: '',
    birthDate: null,
    departments: null,
    bank: null,
    iban: null,
    gender: null,
    city: '',
    address: '',
    zipcode: '',
    comments: '',
    adminComments: '',
    active: 0,
    courses: null,
    fatherName: null,
    motherName: null,
    educationLevel: null,
    familyStatus: null,
    ikaNumber: null,
    amka: null,
    children: null,
    doy: null,
    passportID: null,
    passportDate: null,
    passportOrigin: null,
    cooperationType: null,
});
const rules = {
    fname: { required },
    lname: { required },
    email: { required, email },
    mobile: { required },
};
const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const loading = ref(false);
const toast = useToast();
const schools = ref([]);
const editMode = ref(false);
const schoolsLoading = ref(false);
const defaultTabItems = [
    {
        label: 'Γενικά Στοιχεία',
        icon: 'pi pi-info-circle',
        command: () => {
            setTab(0);
        },
    },
    {
        label: 'Ειδικά Στοιχεία',
        icon: 'pi pi-user-edit',
        command: () => {
            setTab(1);
        },
    },
];
const tabItems = ref([]);
const tabIndex = ref(0);
const classes = ref([]);
const closeCB = ref(null);
const courses = ref([]);

const showTab = ref(false);

const chatUserInfo = computed(() => {
    return {
        _id: state.id,
        username: state.fname + ' ' + state.lname,
        email: state.email,
        role: Utils.Roles.Teacher,
    };
});

const setTab = async (index) => {
    console.log('setTab:' + index);
    await nextTick();
    tabIndex.value = index;

    if (tabIndex.value === 5) {
        console.log('fetch notifications.');
        await nextTick();
        teacherNotifications.value.fetchNotifications(state.id);
    }
};

const panicModeEnabled = computed(() => {
    return globalStore.hasPanicMode;
});

const fixTabs = () => {
    tabItems.value = [...defaultTabItems];
    if (!isAdminOrOffice.value) return;
    if (panicModeEnabled.value) {
        tabItems.value = [...defaultTabItems];
    } else {
        tabItems.value = [
            ...defaultTabItems,
            {
                label: 'Σχόλια',
                icon: 'pi pi-link',
                command: () => {
                    setTab(2);
                },
            },
            {
                label: 'ToDo',
                icon: 'pi pi-fw pi-check-square',
                command: () => {
                    setTab(3);
                },
            },
            {
                label: 'Chat',
                icon: 'pi pi-fw pi-comments',
                command: () => {
                    setTab(4);
                },
            },
            {
                label: 'Mailbox',
                icon: 'pi pi-fw pi-envelope',
                command: () => {
                    setTab(5);
                },
            },
        ];
    }
};

watch(
    panicModeEnabled,
    (newVal) => {
        fixTabs();
    },
    { immediate: true }
);

const show = async (data, onCloseCB) => {
    const { teacherID } = data || {};
    fixTabs();

    console.log(teacherID);
    closeCB.value = onCloseCB;
    lastLogin.value = null;
    classes.value = [];
    tabIndex.value = 0;
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!teacherID;
    headerTitle.value = editMode.value ? 'Καρτέλα Καθηγητή' : 'Νέος Καθηγητής';
    showTab.value = teacherID != null;
    let teacherDepartments = [];
    let schoolID = null;
    if (teacherID != null) {
        state.id = teacherID;
        let res = await RPCApi(RPCMethods.User.GetUserInfoById, { id: teacherID });
        if (res.status == 'ok') {
            const {
                fname,
                lname,
                email,
                mobile,
                school_id,
                ban,
                checked,
                phone,
                afm,
                birthDate,
                gender,
                city,
                address,
                zipCode,
                comments,
                adminComments,
                bank,
                iban,
                last_login_datetime,
                courses,
                father_name,
                mother_name,
                education_level,
                family_status,
                children,
                ika_number,
                amka,
                doy,
                passport_id,
                passport_date,
                passport_origin,
                cooperation_type,
            } = res.data.result.data.userInfo;
            state.fname = fname;
            state.lname = lname;
            state.email = email;
            state.mobile = mobile;
            state.phone = phone;
            state.bank = Utils.BankChoiceOptions.find((x) => x.value === bank);
            state.iban = iban;
            state.afm = afm;
            state.birthDate = Utils.datetimeToDate(birthDate);
            state.gender = Utils.Genders.find((x) => x.value === gender);
            state.city = city;
            state.address = address;
            state.zipcode = zipCode;
            state.comments = comments;
            state.adminComments = adminComments;
            state.active = ban === 1 ? false : true;
            state.courses = courses || null;
            state.fatherName = father_name;
            state.motherName = mother_name;
            state.educationLevel = Utils.EducationLevelOptions.find((x) => x.value === education_level);
            state.familyStatus = Utils.FamilyStatusOptions.find((x) => x.value === family_status);
            state.children = Utils.ChildrenChoices.find((x) => x.value === children);
            state.ikaNumber = ika_number;
            state.amka = amka;
            state.doy = doy;
            state.passportID = passport_id;
            state.passportDate = Utils.datetimeToDate(passport_date);
            state.passportOrigin = passport_origin;
            state.cooperationType = Utils.CooperationTypesOptions.find((x) => x.value === cooperation_type);

            lastLogin.value = last_login_datetime
                ? moment(Utils.datetimeToDate(last_login_datetime)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(last_login_datetime)).format('HH:mm')
                : null;
            schoolID = school_id;

            headerTitle.value = lname + ' ' + fname;
        }

        let departmentsRes = await RPCApi(RPCMethods.Departments.Fetch, { teacherID });
        if (departmentsRes.status == 'ok') {
            teacherDepartments = departmentsRes.data.result.data;
        }
    }

    schoolsLoading.value = true;
    RPCApi(RPCMethods.Schools.Fetch).then((res) => {
        if (res.status == 'ok') {
            schools.value = res.data.result.data.schools;
            categories.value = res.data.result.data.categories;
            if (editMode.value && schoolID) {
                let schoolInfo = schools.value.find((school) => school.id === schoolID);
                let categInfo = categories.value.find((categ) => categ.name === schoolInfo.category);
                state.category = { id: categInfo.id, name: categInfo.name };
                state.subCateg1 = schoolInfo.subCateg1;
                state.subCateg2 = schoolInfo.subCateg2;
            }
        }
        schoolsLoading.value = false;
    });

    RPCApi(RPCMethods.Departments.Fetch).then(async (res) => {
        if (res.status == 'ok') {
            const departmentsData = res.data.result.data;
            departments.value = departmentsData
                .filter((x) => x.title != null && x.title != '')
                .map((department) => {
                    return {
                        id: department.id,
                        name: department.title,
                    };
                });

            state.departments = [];
            if (editMode.value && teacherDepartments.length > 0) {
                for (let department of teacherDepartments) {
                    let departmentInfo = departments.value.find((info) => info.id === department.id);
                    if (!departmentInfo) continue;
                    state.departments.push({ id: departmentInfo.id, name: departmentInfo.name });
                }
            }
        }
    });

    RPCApi(RPCMethods.Courses.Fetch).then((res) => {
        console.log(res);
        let d = [];
        if (res.status == 'ok') {
            const { total, coursesData } = res.data.result.data;
            for (let obj of coursesData) {
                d.push({
                    id: obj.id,
                    title: obj.title,
                });
            }
            allCourses.value = d;

            //remove duplicates title properties
            courses.value = allCourses.value
                .filter((course, index, self) => {
                    return (
                        self.findIndex((t) => {
                            return t.title === course.title;
                        }) === index
                    );
                })
                .map((course) => {
                    return {
                        id: course.id,
                        title: course.title,
                    };
                });
        }
    });
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit', JSON.stringify(state));
    loading.value = true;

    let res;
    let genderValue = state.gender == null ? Utils.GendersEnum.Male : state.gender.value;
    let payload = {
        id: state.id,
        fname: state.fname,
        lname: state.lname,
        email: state.email,
        mobile: state.mobile,
        school_id: null,
        phone: state.phone,
        afm: state.afm,
        birthDate: state.birthDate,
        gender: genderValue,
        departments: state.departments,
        courses: state.courses,
        role: Utils.Roles.Teacher,
        city: state.city,
        address: state.address,
        bank: state.bank?.value != null ? state.bank.value : null,
        iban: state.iban,
        educationLevel: state.educationLevel?.value != null ? state.educationLevel.value : null,
        familyStatus: state.familyStatus?.value != null ? state.familyStatus.value : null,
        children: state.children?.value != null ? state.children.value : null,
        zipCode: state.zipcode,
        ikaNumber: state.ikaNumber,
        amka: state.amka,
        doy: state.doy,
        passportId: state.passportID,
        passportDate: state.passportDate,
        passportOrigin: state.passportOrigin,
        fatherName: state.fatherName,
        motherName: state.motherName,
        cooperationType: state.cooperationType?.value != null ? state.cooperationType.value : null,
        comments: state.comments,
        adminComments: state.adminComments,
        ban: Utils.FromBoolToTinyInt(!state.active),
    };

    console.log(payload);
    if (editMode.value) {
        if (isTeacher.value) {
            res = await RPCApi(RPCMethods.User.UpdateMyProfile, payload);
        } else {
            res = await RPCApi(RPCMethods.User.Update, payload);
        }
    } else {
        res = await RPCApi(RPCMethods.User.Add, payload);
    }

    if (res.status !== 'ok') {
        if (res.status === 'user_already_added') {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Ο χρήστης υπάρχει ήδη!', life: 3000 });
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: res.status, life: 3000 });
        }

        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    if (!editMode.value) {
        setTimeout(() => {
            popupStore.show(Utils.Popups.NotificationDialog, { userID: res.data.result.data?.insertId, audience: Utils.Roles.Teacher, userForcedTemplate: 3 }, (action) => {});
        }, 1000);
    }

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    for (let field in state) {
        if (field === 'active') state[field] = false;
        else state[field] = null;
    }

    submitted.value = false;
    loading.value = false;
};

const onShowBioFilesPopup = async (data) => {
    console.log('onShowBioFilesPopup:' + data);
    showUploaderDialog.value = true;
    uploaderTitle.value = 'Βιογραφικό';
    await nextTick();
    uploader.value.fetchFiles(`teachers/${state.id}/files`);
};

const addTeacherNotification = async () => {
    console.log('addTeacherNotification');
    popupStore.show(Utils.Popups.NotificationDialog, { userID: state.id, audience: Utils.Roles.Teacher }, (action) => {
        console.log('onRequestDialogClosed:' + action);
        teacherNotifications.value.fetchNotifications(state.id);
    });
};

const editTeacherNotification = async (data) => {
    console.log('editTeacherNotification:' + data);
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, userID: state.id, audience: Utils.Roles.Teacher }, (action) => {
        console.log('onRequestDialogClosed:' + action);
        teacherNotifications.value.fetchNotifications(state.id);
    });
};

const cloneTeacherNotification = (data) => {
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, userID: state.id, clone: true, audience: Utils.Roles.Teacher }, (action) => {
        console.log('onNotificationDialogClosed:' + action);
        teacherNotifications.value.fetchNotifications(state.id);
    });
};

const showTeacherNotification = async (data) => {
    console.log('showTeacherNotification:' + data);
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, userID: state.id, showOnly: true, audience: Utils.Roles.Teacher }, (action) => {});
};

const deleteTeacherNotification = async (id) => {
    console.log('deleteTeacherNotification:' + id);
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το mail;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.Notifications.Delete, { id });
            teacherNotifications.value.fetchNotifications(state.id);
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

defineExpose({
    show,
});
</script>

<style lang="scss">
.p-multiselect {
    min-width: 17rem;
}
.p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
}
.p-multiselect-token {
    margin: 0.1rem;
}

.p-avatar {
    img {
        width: auto;
    }
}

.fixed-tab-menu {
    position: sticky;
    top: 0;
    z-index: 1;
}

.box {
    border-radius: 0.75rem;
    border: 2px solid #e3e8ef;
    background: #fff;
}
</style>
