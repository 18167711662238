<template>
    <Dialog
        v-model:visible="dialogShown"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '90vw', height: isAdminOrOffice ? '90vh' : 'auto' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <div v-if="classInfoLoading" class="flex flex-row justify-content-between align-content-center w-full h-full absolute top-0 left-0 z-5 bg-black-alpha-20">
            <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
        </div>
        <TabMenu v-if="showTab" :model="tabItems" class="mb-4 fixed-tab-menu" />
        <div v-if="tabIndex === 0" class="formgrid grid p-fluid">
            <div v-if="!editMode" class="flex flex-column w-full p-2">
                <h5>Επιλέξτε τα μαθήματα για τα οποία θέλετε να δημιουργήσετε ένα τμήμα</h5>
                <DataTable
                    ref="dt1"
                    v-model:selection="selectedCourses"
                    :total-records="totalCourses"
                    :loading="loadingCoursesRequests"
                    :row-hover="true"
                    :value="coursesRequests"
                    data-key="id"
                    responsive-layout="scroll"
                    :rows="coursesRequestsRowsPerPage"
                    :paginator="true"
                    @row-select="onCourseRowSelected"
                    @row-unselect="onCourseRowUnselected"
                >
                    <template #header>
                        <div class="flex flex-row justify-content-between mb-2">
                            <div class="h-3 pr-2">
                                <AutoComplete
                                    v-model="selectedCoursesFilters['category'].value"
                                    :suggestions="filteredCategories"
                                    force-selection
                                    field="name"
                                    placeholder="Κατηγορία"
                                    :dropdown="true"
                                    @item-select="onCategorySelect($event, 'course')"
                                    @complete="searchCategory($event, 'course')"
                                >
                                </AutoComplete>
                            </div>
                            <div class="h-3 pr-2">
                                <AutoComplete
                                    v-model="selectedCoursesFilters['subCateg1'].value"
                                    force-selection
                                    :suggestions="filteredSubCategories1"
                                    placeholder="Υπ/ρία Ι"
                                    :dropdown="true"
                                    @item-select="onSubCateg1Select($event, 'course')"
                                    @complete="searchSubCategory1($event, 'course')"
                                >
                                </AutoComplete>
                            </div>
                            <div class="h-3 pr-2">
                                <AutoComplete
                                    v-model="selectedCoursesFilters['subCateg2'].value"
                                    force-selection
                                    :suggestions="filteredSubCategories2"
                                    placeholder="Υπ/ρία ΙI"
                                    :dropdown="true"
                                    @item-select="onSubCateg2Select($event, 'course')"
                                    @complete="searchSubCategory2($event, 'course')"
                                >
                                </AutoComplete>
                            </div>
                            <Button icon="pi pi-filter-slash" class="p-button-text" @click="resetGlobalFilters('course')" />
                        </div>
                        <div class="flex flex-row justify-content-between align-content-center">
                            <Dropdown
                                v-model="selectedCoursesFilters['examPeriod'].value"
                                :show-clear="true"
                                option-label="label"
                                :options="examPeriods"
                                placeholder="Εξεταστική"
                                @change="filterCoursesByExamPeriod"
                            >
                                <template #value="slotProps">
                                    <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                    <span v-else>Εξεταστική</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{ slotProps.option.label }}</span>
                                </template>
                            </Dropdown>
                            <div class="flex align-items-center justify-content-center">Σύνολο επιλεγμένων αιτήσεων: {{ totalSelectedRequestsNum }}</div>
                        </div>
                    </template>
                    <template #empty> Δεν βρέθηκαν μαθήματα με αιτήσεις. </template>
                    <Column selection-mode="multiple" header-style="width: 3rem"></Column>
                    <Column header="Υπ/ρία Ι" filter-field="subCateg1" field="subCateg1" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                        <template #body="{ data }">
                            {{ data.subCateg1 }}
                        </template>
                    </Column>
                    <Column header="Υπ/ρία ΙI" filter-field="subCateg2" field="subCateg2" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                        <template #body="{ data }">
                            {{ data.subCateg2 }}
                        </template>
                    </Column>
                    <Column header="Μάθημα" filter-field="title" field="title" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                        <template #body="{ data }"> {{ data.title }} {{ data.semester != null ? '(' + data.semester + ')' : '' }} </template>
                    </Column>
                    <Column header="Αιτήσεις" filter-field="requests" field="requests" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                        <template #body="{ data }">
                            <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showCoursesRequestsDialog(data.id)">{{ data.requests }}</a>
                        </template>
                    </Column>
                    <Column :header="`Δεν Ανατέθηκαν (${sumNumNotPosted})`" style="min-width: 10rem" field="numNotPosted" :show-filter-menu="false" :sortable="true">
                        <template #body="{ data }"> {{ data.numNotPosted }}</template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-12">
                <div v-if="isAdminOrOffice">
                    <div class="box w-full h-full flex flex-row" style="height: 12.125rem">
                        <div class="flex flex-column" style="padding-left: 1rem; padding-top: 1rem">
                            <div class="flex flex-row justify-content-between align-content-center gap-2 pb-4" style="width: max-content">
                                <h5 style="margin-bottom: 0; align-self: center">Σχετιζόμενα Μαθήματα</h5>
                                <div class="flex">
                                    <Button icon="pi pi-pencil" @click="openRelatedCoursesDialog"></Button>
                                </div>
                            </div>
                            <div class="flex flex-column gap-4">
                                <template v-for="(item, i) of selectedCourses" :key="i">
                                    <div class="w-full">
                                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showCourseDialog(item.id)">
                                            <span>{{ i + 1 }}) {{ item.subCateg1 }} / {{ item.subCateg2 }} / {{ item.title }} {{ item.semester != null ? '(' + item.semester + ')' : '' }}</span>
                                        </a>
                                    </div>
                                </template>
                                <div v-if="isAdminOrOffice" class="field">
                                    <label :class="{ 'p-error': v$.examPeriod.$invalid && submitted }" for="examPeriod">Εξεταστική*</label>
                                    <Dropdown
                                        v-model="v$.examPeriod.$model"
                                        option-label="label"
                                        :options="examPeriods"
                                        placeholder="Επιλέξτε"
                                        :class="{ 'p-invalid': v$.examPeriod.$invalid && submitted }"
                                        :disabled="!isAdminOrOffice"
                                        style="width: 230px"
                                    >
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                            <span v-else>Επιλέξτε</span>
                                        </template>
                                        <template #option="slotProps">
                                            <span>{{ slotProps.option.label }}</span>
                                        </template>
                                    </Dropdown>
                                    <small v-if="(v$.examPeriod.$invalid && submitted) || v$.examPeriod.$pending.$response" class="p-error">{{ v$.examPeriod.required.$message }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isAdminOrOffice" class="box w-full h-fit flex flex-row" style="height: 12.125rem; margin-top: 1rem">
                    <div class="flex flex-column w-full" style="padding-left: 1rem; padding-right: 1rem; padding-top: 1rem">
                        <h5>Στοιχεία τμήματος</h5>
                        <div class="flex flex-row w-full gap-4">
                            <div class="flex flex-column w-full">
                                <div v-if="isAdminOrOffice" class="field">
                                    <label :class="{ 'p-error': v$.title.$invalid && submitted }" for="title">Τίτλος*</label>
                                    <InputText
                                        id="title"
                                        v-model="v$.title.$model"
                                        autocomplete="off"
                                        spellcheck="off"
                                        placeholder="Τίτλος"
                                        :class="{ 'p-invalid': v$.title.$invalid && submitted }"
                                        :disabled="!isAdminOrOffice"
                                    />
                                    <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{ v$.title.required.$message }}</small>
                                </div>
                                <div v-if="isAdminOrOffice" class="field">
                                    <label :class="{ 'p-error': v$.status.$invalid && submitted }" for="status">Κατάσταση*</label>
                                    <Dropdown
                                        v-model="v$.status.$model"
                                        option-label="label"
                                        :options="Utils.ClassStatuses"
                                        placeholder="Επιλέξτε"
                                        :class="{ 'p-invalid': v$.status.$invalid && submitted }"
                                        :disabled="!isAdminOrOffice || !editMode"
                                    >
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value != null" :class="`cls-status-badge cls-status-${slotProps.value.value}`">{{ slotProps.value.label }}</span>
                                            <span v-else>Επιλέξτε</span>
                                        </template>
                                        <template #option="slotProps">
                                            <span class="cls-status-badge" :class="`cls-status-${slotProps.option.value}`">{{ slotProps.option.label }}</span>
                                        </template>
                                    </Dropdown>
                                    <small v-if="(v$.status.$invalid && submitted) || v$.status.$pending.$response" class="p-error">{{ v$.status.required.$message }}</small>
                                </div>
                                <div v-if="isAdminOrOffice" class="field">
                                    <label :class="{ 'p-error': v$.startDate.$invalid && submitted }" for="createTime">Ημερομηνία έναρξης*</label>
                                    <Calendar
                                        id="createTime"
                                        v-model="v$.startDate.$model"
                                        date-format="dd/mm/yy"
                                        placeholder="Επιλογή Ημ/νίας"
                                        :class="{ 'p-invalid': v$.startDate.$invalid && submitted }"
                                        :disabled="!isAdminOrOffice"
                                        :readonly="!isAdminOrOffice"
                                    />
                                    <small v-if="(v$.startDate.$invalid && submitted) || v$.startDate.$pending.$response" class="p-error">{{ v$.startDate.required.$message }}</small>
                                </div>
                                <div v-if="isAdminOrOffice" class="field">
                                    <label for="phaseCurrent">Ώρες που έγιναν</label>
                                    <InputNumber
                                        id="phaseCurrent"
                                        v-model="state.phaseCurrent"
                                        disabled
                                        :readonly="true"
                                        mode="decimal"
                                        :min-fraction-digits="0"
                                        locale="el-GR"
                                        autocomplete="off"
                                        spellcheck="off"
                                        placeholder="0"
                                    />
                                </div>

                                <div v-if="isAdminOrOffice" class="field">
                                    <label :class="{ 'p-error': v$.classroom.$invalid && submitted }" for="classroom">Αίθουσα*</label>
                                    <Dropdown
                                        id="classroom"
                                        v-model="v$.classroom.$model"
                                        option-label="label"
                                        :options="classrooms"
                                        placeholder="Επιλέξτε"
                                        :class="{ 'p-invalid': v$.classroom.$invalid && submitted }"
                                        :disabled="!isAdminOrOffice"
                                        :readonly="!isAdminOrOffice"
                                    >
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                            <span v-else>Επιλέξτε</span>
                                        </template>
                                        <template #option="slotProps">
                                            <span>{{ slotProps.option.label }}</span>
                                        </template>
                                    </Dropdown>
                                    <small v-if="(v$.classroom.$invalid && submitted) || v$.classroom.$pending.$response" class="p-error">{{ v$.classroom.required.$message }}</small>
                                </div>
                            </div>
                            <div class="flex flex-column w-full">
                                <div v-if="isAdminOrOffice" class="field">
                                    <label :class="{ 'p-error': v$.teacher.$invalid && submitted }" for="teacher">Καθηγητής*</label>
                                    <AutoComplete
                                        id="teacher"
                                        v-model="v$.teacher.$model"
                                        :suggestions="filteredTeachers"
                                        force-selection
                                        :field="teacherLblFunction"
                                        placeholder="Ονοματεπώνυμο"
                                        :delay="600"
                                        :class="{ 'p-invalid': v$.teacher.$invalid && submitted }"
                                        :disabled="!isAdminOrOffice"
                                        @complete="searchTeachers($event)"
                                    >
                                    </AutoComplete>
                                    <small v-if="(v$.teacher.$invalid && submitted) || v$.teacher.$pending.$response" class="p-error">{{ v$.teacher.required.$message }}</small>
                                </div>
                                <div v-if="isAdminOrOffice" class="field">
                                    <label :class="{ 'p-error': v$.type.$invalid && submitted }" for="type">Τύπος*</label>
                                    <Dropdown
                                        v-model="v$.type.$model"
                                        option-label="label"
                                        :options="Utils.Types"
                                        placeholder="Επιλέξτε"
                                        :class="{ 'p-invalid': v$.type.$invalid && submitted }"
                                        :disabled="!isAdminOrOffice"
                                    >
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value != null && slotProps.value.value != null" class="private-badge" :class="`private-${slotProps.value.value === 1}`">{{
                                                slotProps.value.label
                                            }}</span>
                                            <span v-else>Επιλέξτε</span>
                                        </template>
                                        <template #option="slotProps">
                                            <span class="private-badge" :class="`private-${slotProps.option.value === 1}`">{{ slotProps.option.label }}</span>
                                        </template>
                                    </Dropdown>
                                    <small v-if="(v$.type.$invalid && submitted) || v$.type.$pending.$response" class="p-error">{{ v$.type.required.$message }}</small>
                                </div>
                                <div v-if="isAdminOrOffice" class="field">
                                    <label for="classPrice">Τιμή</label>
                                    <InputNumber
                                        id="classPrice"
                                        v-model="state.classPrice"
                                        mode="decimal"
                                        :min-fraction-digits="2"
                                        locale="el-GR"
                                        autocomplete="off"
                                        spellcheck="off"
                                        placeholder="0"
                                    />
                                </div>
                                <div v-if="isAdminOrOffice" class="field">
                                    <label for="phaseTotal">Σύνολο ωρών</label>
                                    <InputNumber
                                        id="phaseTotal"
                                        v-model="state.phaseTotal"
                                        mode="decimal"
                                        :min-fraction-digits="2"
                                        locale="el-GR"
                                        autocomplete="off"
                                        spellcheck="off"
                                        placeholder="0"
                                        :disabled="!isAdminOrOffice"
                                        :readonly="!isAdminOrOffice"
                                    />
                                </div>
                                <div v-if="isAdminOrOffice" class="field-checkbox">
                                    <Checkbox v-model="state.onSite" :disabled="!isAdminOrOffice" :binary="true" />
                                    <label>On-Site</label>
                                </div>
                                <div v-if="isAdminOrOffice" class="field-checkbox">
                                    <Checkbox v-model="state.allowNotifications" :binary="true" />
                                    <label>Ειδοποιήσεις</label>
                                </div>
                                <div v-if="isAdminOrOffice" class="field">
                                    <label :class="{ 'p-error': v$.maxVideosViewsNum.$invalid && submitted }">Μέγιστος αριθμός θεάσεων (videos)</label>
                                    <InputNumber
                                        v-model="v$.maxVideosViewsNum.$model"
                                        mode="decimal"
                                        :min-fraction-digits="0"
                                        locale="el-GR"
                                        autocomplete="off"
                                        spellcheck="off"
                                        placeholder="5"
                                        :class="{ 'p-invalid': v$.maxVideosViewsNum.$invalid && submitted }"
                                    />
                                    <small v-if="(v$.maxVideosViewsNum.$invalid && submitted) || v$.maxVideosViewsNum.$pending.$response" class="p-error">{{
                                        v$.maxVideosViewsNum.required.$message
                                    }}</small>
                                </div>
                            </div>
                        </div>
                        <div v-if="isAdminOrOffice" class="field">
                            <label for="comments">Σχόλια Τμήματος</label>
                            <Textarea id="comments" v-model="state.comments" autocomplete="off" spellcheck="off" rows="5" />
                        </div>
                    </div>
                </div>

                <div v-if="!isAdminOrOffice" class="flex flex-row p-4">
                    <div class="flex flex-column w-full gap-4">
                        <div class="flex flex-column gap-1">
                            <div class="flex">
                                <span style="font-weight: 800">Μαθήματα</span>
                            </div>
                            <div class="flex w-full">
                                <div class="flex flex-column w-full">
                                    <template v-for="(item, i) in selectedCourses" :key="i">
                                        <span class="pr-2">{{ item.subCateg1 }} / {{ item.subCateg2 }} / {{ item.title }} {{ item.semester != null ? '(' + item.semester + ')' : '' }}</span>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-column gap-1">
                            <div class="flex">
                                <span style="font-weight: 800">Εξεταστική</span>
                            </div>
                            <div class="flex w-full">
                                <span v-if="state.examPeriod">{{ state.examPeriod?.label }}</span>
                            </div>
                        </div>

                        <div class="flex flex-column gap-1">
                            <div class="flex">
                                <span style="font-weight: 800">Κατάσταση</span>
                            </div>
                            <div class="flex w-full">
                                <span :class="`cls-status-badge cls-status-${state.status.value}`">{{ state.status.label }}</span>
                            </div>
                        </div>

                        <div class="flex flex-column gap-1">
                            <div class="flex">
                                <span style="font-weight: 800">Καθηγητής</span>
                            </div>
                            <div class="flex w-full">
                                <span>{{ state.teacher?.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-column w-full gap-4">
                        <div class="flex flex-column gap-1">
                            <div class="flex">
                                <span style="font-weight: 800">Ημερομηνία έναρξης</span>
                            </div>
                            <div class="flex w-full">
                                <span v-if="state.startDate">{{ moment(state.startDate).format('DD/MM/YYYY') }}</span>
                            </div>
                        </div>

                        <div class="flex flex-column gap-1">
                            <div class="flex">
                                <span style="font-weight: 800">Ώρες που έγιναν</span>
                            </div>
                            <div class="flex w-full">
                                <span>{{ state.phaseCurrent || '--' }}</span>
                            </div>
                        </div>

                        <div class="flex flex-column gap-1">
                            <div class="flex w-full">
                                <span style="font-weight: 800">Σύνολο ωρών</span>
                            </div>
                            <div class="flex">
                                <span>{{ state.phaseTotal }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="tabIndex === 1">
            <h5>Εγγεγραμμένοι: {{ totalStudents }}</h5>
            <DataTable
                ref="studentsTable"
                v-model:filters="studentsTableFilters"
                :total-records="totalStudents"
                :loading="loadingStudents"
                :row-hover="true"
                :value="students"
                data-key="requestId"
                responsive-layout="scroll"
                :rows="studentsRowsPerPage"
                :paginator="true"
            >
                <template #empty> Δεν βρέθηκαν μαθητές. </template>
                <Column header="Ονοματεπώνυμο" field="fullname" filter-field="lname" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span v-if="!isAdminOrOffice" class="font-medium no-underline text-left">{{ data.fullname }}</span>
                        <a v-else class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showStudentDialog(data.id)">{{ data.fullname }}</a>
                    </template>
                </Column>
                <!-- <Column header="Email" filter-field="email" field="email" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        {{ data.email }}
                    </template>
                </Column> -->
                <Column v-if="isAdminOrOffice || isTeacher" field="status" filter-field="status" header="Αίτηση" body-class="text-left" style="min-width: 10rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span class="status-badge" :class="`status-${data.status}`">{{ Utils.RequestStatusToString(data.status) }}</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown
                            v-model="filterModel.value"
                            option-label="label"
                            :options="Utils.RequestStatuses"
                            placeholder="Όλες"
                            class="p-column-filter"
                            :show-clear="true"
                            @change="filterCallback"
                        >
                            <template #value="slotProps">
                                <span v-if="slotProps.value != null" :class="`status-badge status-${slotProps.value.value}`">{{ slotProps.value.label }}</span>
                                <span v-else>Όλες</span>
                            </template>
                            <template #option="slotProps">
                                <span class="status-badge" :class="`status-${slotProps.option.value}`">{{ slotProps.option.label }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Παρουσίες (σε ώρες)" field="sumHoursDuration" filter-field="sumHoursDuration" style="min-width: 10rem">
                    <template #body="{ data }">
                        <a
                            class="font-medium no-underline text-left cursor-pointer"
                            style="color: blue"
                            @click="showStudentAttendancesDialog({ userID: data.id, classID: state.id, username: data.fullname })"
                            >{{ Utils.toHoursAndMinutes(data.sumHoursDuration) }}</a
                        >
                    </template>
                </Column>
                <Column v-if="isAdminOrOffice" header="Δίδακτρα" field="offer" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showOfferDialog(data.requestID)">{{ Utils.toEUR(data.offer) }}</a>
                    </template>
                </Column>
                <Column v-if="isAdminOrOffice" field="isPrivate" filter-field="isPrivate" header="" body-class="text-left" style="min-width: 10rem">
                    <template #body="{ data }">
                        <span class="private-badge" :class="`private-${data.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.isPrivate)].label }}</span>
                    </template>
                </Column>
                <Column v-if="isAdminOrOffice" header="Σύνολο" field="classSum" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span style="font-weight: 800"> {{ Utils.toEUR(data.classSum) }}</span>
                    </template>
                </Column>
                <Column v-if="isAdminOrOffice" header="Υπόλοιπο" field="dues" style="min-width: 10rem" :sortable="true">
                    <template #body="slotProps">
                        <span class="dues-badge" :class="`status-${slotProps.data.duesType}`">{{ Utils.toEUR(slotProps.data.dues) }}</span>
                    </template>
                </Column>
                <Column field="ban" filter-field="ban" header="Ενεργός" data-type="boolean" body-class="text-left" :sortable="true">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'true-icon pi-check-circle': !data.ban, 'false-icon pi-times-circle': data.ban }"></i>
                    </template>
                </Column>
                <Column v-if="isAdminOrOffice" header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button type="button" icon="pi pi-pencil" class="p-button-text" @click="showRequestDialog(data.requestID)"></Button>
                    </template>
                </Column>
                <Column v-if="isAdminOrOffice" header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button type="button" icon="pi pi-user-minus" class="p-button-text" @click="onRequestRemove(data.requestID)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-if="tabIndex === 2 && isAdminOrOffice">
            <h5>Αιτήσεις (Σύνολο: {{ totalRequests }})</h5>
            <DataTable
                ref="rt"
                v-model:filters="tableRequestsFilters"
                :lazy="true"
                :total-records="totalRequests"
                :loading="loadingRequests"
                :row-hover="true"
                :value="requests"
                filter-display="row"
                data-key="id"
                responsive-layout="scroll"
                :rows="requestsRowsPerPage"
                :paginator="true"
                @page="onRequestsPage($event)"
                @sort="onRequestsSort($event)"
                @filter="onRequestsFilter($event)"
            >
                <template #header>
                    <div class="flex flex-row justify-content-between">
                        <div class="flex-none">
                            <Button icon="pi pi-plus" label="Νέα Αίτηση" class="mr-2 mb-2" @click="addRequest" />
                        </div>
                        <div class="flex flex-row">
                            <div class="h-3 pr-2">
                                <AutoComplete
                                    id="category"
                                    v-model="tableRequestsFilters['category'].value"
                                    :suggestions="filteredCategories"
                                    force-selection
                                    field="name"
                                    placeholder="Κατηγορία"
                                    :dropdown="true"
                                    @item-select="onCategorySelect"
                                    @complete="searchCategory($event)"
                                >
                                </AutoComplete>
                            </div>
                            <div class="h-3 pr-2">
                                <AutoComplete
                                    id="subCateg1"
                                    v-model="tableRequestsFilters['subCateg1'].value"
                                    force-selection
                                    :suggestions="filteredSubCategories1"
                                    placeholder="Υπ/ρία Ι"
                                    :dropdown="true"
                                    @item-select="onSubCateg1Select"
                                    @complete="searchSubCategory1($event)"
                                >
                                </AutoComplete>
                            </div>
                            <div class="h-3 pr-2">
                                <AutoComplete
                                    id="subCateg2"
                                    v-model="tableRequestsFilters['subCateg2'].value"
                                    force-selection
                                    :suggestions="filteredSubCategories2"
                                    placeholder="Υπ/ρία ΙI"
                                    :dropdown="true"
                                    @item-select="onSubCateg2Select"
                                    @complete="searchSubCategory2($event)"
                                >
                                </AutoComplete>
                            </div>
                            <div class="h-3 pr-2">
                                <AutoComplete
                                    id="title"
                                    v-model="tableRequestsFilters['courseTitle'].value"
                                    :suggestions="filteredCourses"
                                    force-selection
                                    :field="courseRequestsTitleLblFunction"
                                    placeholder="Mάθημα"
                                    :dropdown="true"
                                    @item-select="onCourseTitleSelect"
                                    @complete="searchRequestsCourses($event)"
                                >
                                </AutoComplete>
                            </div>
                            <Button icon="pi pi-filter-slash" class="mr-2 mb-2 p-button-text" @click="resetGlobalFilters" />
                        </div>
                    </div>
                </template>
                <template #empty> Δεν βρέθηκαν αιτήσεις. </template>
                <Column header="ID" field="id" style="min-width: 2rem">
                    <template #body="{ data }">
                        {{ data.id }}
                    </template>
                </Column>
                <Column header="Μάθημα" field="title" filter-field="title" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        {{ data.title }}
                    </template>
                </Column>
                <Column header="Σχολή" field="school" filter-field="school" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        {{ data.school }}
                    </template>
                </Column>
                <Column v-if="isAdminOrOffice" field="isPrivate" filter-field="isPrivate" header="Τύπος" data-type="boolean" body-class="text-left" style="min-width: 8rem">
                    <template #body="{ data }">
                        <span class="private-badge" :class="`private-${data.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.isPrivate)].label }}</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" option-label="label" :options="Utils.Types" placeholder="Όλοι" class="p-column-filter" :show-clear="true" @change="filterCallback">
                            <template #value="slotProps">
                                <span v-if="slotProps.value != null" class="private-badge" :class="`private-${slotProps.value.value === 1}`">{{ slotProps.value.label }}</span>
                                <span v-else>Όλοι</span>
                            </template>
                            <template #option="slotProps">
                                <span class="private-badge" :class="`private-${slotProps.option.value === 1}`">{{ slotProps.option.label }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="status" filter-field="status" header="Κατάσταση" body-class="text-left" style="min-width: 10rem" :show-filter-menu="false">
                    <template #body="{ data }">
                        <span class="status-badge" :class="`status-${data.status}`">{{ Utils.RequestStatusToString(data.status) }}</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown
                            v-model="filterModel.value"
                            option-label="label"
                            :options="Utils.RequestStatuses"
                            placeholder="Όλες"
                            class="p-column-filter"
                            :show-clear="true"
                            @change="filterCallback"
                        >
                            <template #value="slotProps">
                                <span v-if="slotProps.value != null" :class="`status-badge status-${slotProps.value.value}`">{{ slotProps.value.label }}</span>
                                <span v-else>Όλες</span>
                            </template>
                            <template #option="slotProps">
                                <span class="status-badge" :class="`status-${slotProps.option.value}`">{{ slotProps.option.label }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Εξεταστική" field="examPeriod" filter-field="examPeriod" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        {{ data.examsPeriodTitle }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" option-label="label" :options="examPeriods" placeholder="Όλες" class="p-column-filter" :show-clear="true" @change="filterCallback">
                            <template #value="slotProps">
                                <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                <span v-else>Όλες</span>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option.label }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column header="Μαθητής" field="studentName" filter-field="studentName" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                    <template #body="{ data }">
                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showStudentDialog(data.studentID)">{{ data.studentName }}</a>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση ονόματος" @keyup="filterCallback()" />
                    </template>
                </Column>
                <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button type="button" icon="pi pi-user-plus" class="p-button-text" @click="addRequestToClass(data.id)"></Button>
                    </template>
                </Column>
                <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button type="button" icon="pi pi-pencil" class="p-button-text" @click="showRequestDialog(data.id)"></Button>
                    </template>
                </Column>
                <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button type="button" icon="pi pi-copy" class="p-button-text" @click="cloneRequest(data.id)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-if="tabIndex === 3">
            <div>
                <h5>Σύνολο μαθημάτων: {{ totalLessons }}</h5>
                <DataTable
                    ref="lessonsTable"
                    v-model:filters="lessonsTableFilters"
                    :total-records="totalLessons"
                    :loading="loadingLessons"
                    :row-hover="true"
                    :value="lessons"
                    data-key="id"
                    responsive-layout="scroll"
                    :rows="lessonsRowsPerPage"
                    :paginator="true"
                >
                    <template v-if="isAdminOrOffice" #header>
                        <div class="flex flex-row justify-content-between">
                            <div class="flex-none">
                                <Button icon="pi pi-plus" label="Νέo Μάθημα" class="mr-2 mb-2" @click="addLesson" />
                            </div>
                        </div>
                    </template>
                    <template #empty> Δεν βρέθηκαν μαθήματα. </template>
                    <Column v-if="isAdminOrOffice" header="ID" field="id" style="min-width: 2rem">
                        <template #body="{ data }">
                            <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="editLessonDialog(data)">{{ data.id }}</a>
                        </template>
                    </Column>
                    <!-- <Column v-if="isAdminOrOffice" field="classroom" filter-field="classroom" header="Αίθουσα" body-class="text-left" style="min-width: 8rem" :sortable="true">
                        <template #body="{ data }">
                            <span>{{ data.classroom }}</span>
                        </template>
                    </Column> -->
                    <Column field="date" filter-field="date" sort-field="startDate" header="Ημέρα" body-class="text-left" style="min-width: 8rem" :sortable="true">
                        <template #body="{ data }">
                            <span>{{ data.date }}</span>
                        </template>
                    </Column>
                    <Column field="date" header="Ώρα" body-class="text-left" style="min-width: 8rem" :sortable="false">
                        <template #body="{ data }">
                            <span>{{ data.time }}</span>
                        </template>
                    </Column>
                    <Column v-if="isAdminOrOffice || isTeacher" field="submitted" filter-field="submitted" header="Παρουσίες" body-class="text-left" style="min-width: 8rem">
                        <template #body="{ data }">
                            <a
                                v-if="data.lessonCanceled === 1"
                                class="font-medium no-underline text-left cursor-pointer"
                                style="color: #000000"
                                @click="showAttendancesDialog({ lessonID: data.id, classID: state.id, classroom: data.classroom, lessonDate: data.dateRange, commited: data.submitted })"
                                >Ακυρώθηκε</a
                            >
                            <a
                                v-else-if="!data.submitted"
                                class="font-medium no-underline text-left cursor-pointer"
                                style="color: #ff60a8"
                                @click="showAttendancesDialog({ lessonID: data.id, classID: state.id, classroom: data.classroom, lessonDate: data.dateRange, commited: data.submitted })"
                                >Εκκρεμούν</a
                            >
                            <a
                                v-else-if="data.commitedBy === Utils.Roles.Teacher"
                                class="font-medium no-underline text-left cursor-pointer"
                                style="color: #ea8553"
                                @click="showAttendancesDialog({ lessonID: data.id, classID: state.id, classroom: data.classroom, lessonDate: data.dateRange, commited: data.submitted })"
                                >Υποβλήθηκαν (από Καθηγητή)</a
                            >
                            <a
                                v-else
                                class="font-medium no-underline text-left cursor-pointer"
                                style="color: #28a745"
                                @click="showAttendancesDialog({ lessonID: data.id, classID: state.id, classroom: data.classroom, lessonDate: data.dateRange, commited: data.submitted })"
                                >Υποβλήθηκαν</a
                            >
                        </template>
                    </Column>
                    <Column v-if="isStudent" field="status" header="Κατάσταση" header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                        <template #body="{ data }">
                            <div class="flex flex-row align-items-center justify-items-start">
                                <span>
                                    {{ lessonStatus(data) }}
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column v-if="zoomLinksAllowed" field="zoomURL" filter-field="zoomURL" header="Zoom Meeting" body-class="text-left" style="min-width: 8rem">
                        <template #body="{ data }">
                            <div v-if="isAdminOrOffice">
                                {{ data.zoomURL }}
                            </div>
                            <div v-else class="flex flex-row align-items-center justify-items-start" :class="{ 'inactive-zoom-link': !isZoomLinkActive(data) }" @click="handleZoomClick(data)">
                                <i class="zoom-lbl-icon" style="vertical-align: center"></i>
                                <div style="position: relative">
                                    <img
                                        class="cursor-pointer mt-1"
                                        src="@/assets/images/playZoom.png"
                                        style="position: absolute; top: 50%; transform: translateY(-50%); width: 3.5rem; height: 3.5rem"
                                    />
                                </div>
                            </div>
                        </template>
                    </Column>
                    <!-- <Column v-if="isAdminOrOffice" header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button :disabled="data.submitted" type="button" icon="pi pi-send" class="p-button-text" @click="notifyStudentsForLesson(data)"></Button>
                    </template>
                </Column> -->
                    <!-- <Column v-if="isAdminOrOffice" header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                        <template #body="{ data }">
                            <Button type="button" icon="pi pi-pencil" class="p-button-text" @click="editLessonDialog(data)"></Button>
                        </template>
                    </Column> -->
                    <Column v-if="isAdminOrOffice" header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                        <template #body="{ data }">
                            <Button :disabled="data.submitted" type="button" icon="pi pi-trash" class="p-button-text p-button-danger" @click="showDeleteLessonDialog(data.id)"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div v-if="tabIndex === 4">
            <h5>Σύνολο μηνυμάτων: {{ totalNotifications }}</h5>
            <DataTable
                ref="notificationsTable"
                v-model:filters="notificationsTableFilters"
                :total-records="totalNotifications"
                :loading="loadingNotifications"
                :row-hover="true"
                :value="notifications"
                data-key="id"
                responsive-layout="scroll"
                :rows="notificationsRowsPerPage"
                :paginator="true"
            >
                <template #header>
                    <div class="flex flex-row justify-content-between">
                        <div class="flex-none">
                            <Button icon="pi pi-plus" label="Νέο μήνυμα" class="mr-2 mb-2" @click="addNotification" />
                        </div>
                    </div>
                </template>
                <template #empty> Δεν βρέθηκαν μηνύματα </template>
                <Column header="ID" field="id" style="min-width: 2rem">
                    <template #body="{ data }">
                        {{ data.id }}
                    </template>
                </Column>
                <Column field="title" filter-field="title" sort-field="title" header="Τίτλος" body-class="text-left" style="min-width: 8rem" :sortable="true">
                    <template #body="{ data }">
                        <span>{{ data.title }}</span>
                    </template>
                </Column>
                <Column field="type" filter-field="type" sort-field="type" header="Τύπος" body-class="text-left" style="min-width: 8rem" :sortable="true">
                    <template #body="{ data }">
                        <span>{{ Utils.NotificationTypeToString(data.type) }}</span>
                    </template>
                </Column>
                <Column field="runAt" filter-field="runAt" sort-field="runAt" header="Ημερομηνία" body-class="text-left" style="min-width: 8rem" :sortable="true">
                    <template #body="{ data }">
                        <span>{{ data.runAt }}</span>
                    </template>
                </Column>
                <Column field="status" filter-field="status" sort-field="status" header="Κατάσταση" body-class="text-left" style="min-width: 8rem" :sortable="true">
                    <template #body="slotProps">
                        <span
                            v-if="slotProps.data.type === Utils.NotificationType.Email || slotProps.data.type === Utils.NotificationType.PushNotification"
                            class="notification-status-badge"
                            :class="`status-${slotProps.data.status}`"
                            >{{ Utils.NotificationStatusToString(slotProps.data.status) }}</span
                        >
                        <span v-else>--</span>
                    </template>
                </Column>

                <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button v-if="data.status === 1" type="button" icon="pi pi-eye" class="p-button-text" @click="showNotificationDialog(data)"></Button>
                    </template>
                </Column>
                <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button type="button" icon="pi pi-copy" class="p-button-text" @click="cloneNotification(data)"></Button>
                    </template>
                </Column>
                <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button :disabled="data.status === 1" type="button" icon="pi pi-pencil" class="p-button-text" @click="editNotificationDialog(data)"></Button>
                    </template>
                </Column>
                <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button :disabled="data.status === 1" type="button" icon="pi pi-trash" class="p-button-text p-button-danger" @click="showDeleteNotificationDialog(data.id)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
        <!-- <div v-if="tabIndex === 5">
            <Chat v-if="chatAllowed" chatH="60vh" :classInfo="state"></Chat>
            <div v-else style="font-weight: bold; color: red">Access Denied</div>
        </div> -->
        <div v-if="tabIndex === 6">
            <Uploader v-if="filesAllowed" ref="uploader"></Uploader>
            <div v-else style="font-weight: bold; color: red">Access Denied</div>
        </div>
        <div v-if="tabIndex === 7">
            <Videos v-if="videosAllowed" ref="videos" :show-first-only="showFirstVideoOnly"></Videos>
            <div v-else style="font-weight: bold; color: red">Access Denied</div>
        </div>
        <div v-if="tabIndex === 8"><Todos ref="todos" :type="Utils.TodoType.Class" :typeRefererId="state.id" /></div>
        <template v-if="tabIndex === 0 && isAdminOrOffice" #footer>
            <div class="flex flex-row justify-content-between align-content-center w-full h-full pt-4">
                <div class="flex">
                    <Button v-if="isAdmin" label="Εκκαθάριση" icon="pi pi-trash" class="p-button-danger" :loading="loading" @click="deleteClassData" />
                </div>
                <div class="flex">
                    <Button v-if="isAdminOrOffice" label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3 p-button-secondary" @click="onHide(false)" />
                    <Button v-if="isAdminOrOffice" label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
                </div>
            </div>
        </template>
    </Dialog>
    <Dialog v-model:visible="coursesDialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true">
        <Courses :isSelectionMode="true" :selectedCourses="selectedCourses" @selected-courses-changed="onSelectedCoursesChanged"></Courses>
        <template #footer>
            <div class="flex flex-row justify-content-end align-content-center w-full h-full pt-4">
                <div class="flex">
                    <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3 p-button-secondary" @click="coursesDialogShown = false" />
                </div>
                <div class="flex">
                    <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" @click="onCoursesSelection" />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import Todos from '@/components/Todos.vue';
import moment from 'moment/min/moment-with-locales';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watch, toRefs, nextTick } from 'vue';
import { required, integer, email, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import { useDebounceFn } from '@vueuse/core';
import { usePopupStore } from '@/stores/PopupStore.js';
import Chat from '@/components/Chat.vue';
import Uploader from '@/components/Uploader.vue';
import Videos from '@/components/Videos.vue';
import loaderCircle from '@/assets/lottie/loaderCircle.json';
import { useChatStore } from '@/stores/ChatStore';
import Courses from '@/components/Courses.vue';

const coursesDialogShown = ref(false);
const chatStore = useChatStore();
const rt = ref(null);
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const loadingStudents = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const filteredTeachers = ref([]);
const classrooms = ref([]);
const filteredCourses = ref([]);
const coursesOptions = ref([]);
const students = ref([]);
const studentsTable = ref(null);
const studentsRowsPerPage = ref(10);
const requestsRowsPerPage = ref(10);
const requests = ref([]);
const filteredCategories = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const categories = ref([]);
const schools = ref([]);
const notificationsRowsPerPage = ref(10);
const uploader = ref(null);
const videos = ref(null);
const examPeriodFilter = ref(null);
const selectedCoursesFilters = ref({
    courseTitle: { value: null },
    examPeriod: { value: null },
    category: { value: null },
    subCateg1: { value: null },
    subCateg2: { value: null },
});
const popupStore = usePopupStore();
const studentsTableFilters = ref({
    lname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
});
const lessonsTableFilters = ref({
    classroom: { value: null, matchMode: FilterMatchMode.EQUALS },
});
const notificationsTableFilters = ref({
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
});
const lessonsTable = ref(null);
const totalLessons = ref(0);
const loadingLessons = ref(false);
const lessons = ref([]);
const lessonsRowsPerPage = ref(10);
const confirm = useConfirm();
const notificationsTable = ref();
const loadingNotifications = ref(false);

const dt1 = ref(null);
const freshSelectedCourses = ref([]);
const selectedCourses = ref([]);
const totalCourses = ref(0);
const loadingCoursesRequests = ref(false);
const coursesRequests = ref([]);
const coursesRequestsRowsPerPage = ref(10);
const totalSelectedRequests = ref(null);
const closeCB = ref(null);
const sumNumNotPosted = ref(0);

// const chatAllowed = computed(() => {
//     if (isAdminOrOffice.value || isTeacher.value) return true;
//     if ((studentClassRequest.value?.rights & Utils.RequestRights.Chat) === Utils.RequestRights.Chat) return true;

//     return false;
// });

const filesAllowed = computed(() => {
    if (isAdminOrOffice.value || isTeacher.value) return true;
    if ((studentClassRequest.value?.rights & Utils.RequestRights.Files) === Utils.RequestRights.Files) return true;

    return false;
});

const zoomLinksAllowed = computed(() => {
    if (isAdminOrOffice.value || isTeacher.value) return true;
    if ((studentClassRequest.value?.rights & Utils.RequestRights.ZoomLinks) === Utils.RequestRights.ZoomLinks) return true;

    return false;
});

const videosAllowed = computed(() => {
    if (isAdminOrOffice.value || isTeacher.value) return true;
    if ((studentClassRequest.value?.rights & Utils.RequestRights.Videos) === Utils.RequestRights.Videos) return true;

    return false;
});

const showFirstVideoOnly = computed(() => {
    if (isAdminOrOffice.value || isTeacher.value) return false;
    if (studentClassRequest.value?.status === Utils.RequestStatus.PreRegistered) return true;

    return false;
});

const state = reactive({
    id: null,
    title: null,
    startDate: null,
    onSite: null,
    firstLesson: null,
    teacher: null,
    phaseCurrent: null,
    phaseTotal: null,
    examPeriod: null,
    status: null,
    comments: null,
    type: null,
    classroom: null,
    accountable: null,
    classPrice: null,
    allowNotifications: true,
    maxVideosViewsNum: 5,
});
const rules = {
    title: { required },
    status: { required },
    teacher: { required },
    startDate: { required },
    examPeriod: { required },
    type: { required },
    classroom: { required },
    maxVideosViewsNum: { required },
};
const fetchRequestsParams = ref({});
const classInfoLoading = ref(true);

const v$ = useVuelidate(rules, state);
const loading = ref(false);
const toast = useToast();
const editMode = ref(false);
const examPeriods = ref([]);
const accountables = ref([]);
const chatLabel = ref('Chat');
const tabItems = computed(() => {
    if (isAdminOrOffice.value) {
        return [
            {
                label: 'Στοιχεία',
                icon: 'pi pi-pencil',
                command: () => {
                    setTab(0);
                },
            },
            {
                label: 'Μαθητές',
                icon: 'pi pi-fw pi-users',
                command: () => {
                    setTab(1);
                },
            },
            {
                label: 'Αιτήσεις',
                icon: 'pi pi-fw pi-file',
                command: () => {
                    setTab(2);
                },
            },
            {
                label: 'Πρόγραμμα',
                icon: 'pi pi-fw pi-calendar',
                command: () => {
                    setTab(3);
                },
            },
            {
                label: 'Mailbox',
                icon: 'pi pi-fw pi-envelope',
                command: () => {
                    setTab(4);
                },
            },
            // {
            //     label: chatLabel.value,
            //     icon: 'pi pi-fw pi-comments',
            //     command: () => {
            //         setTab(5);
            //     },
            // },
            {
                label: 'Αρχεία',
                icon: 'pi pi-fw pi-file-pdf',
                command: () => {
                    setTab(6);
                },
            },
            {
                label: 'Video',
                icon: 'pi pi-fw pi-video',
                command: () => {
                    setTab(7);
                },
            },
            {
                label: 'ToDo',
                icon: 'pi pi-fw pi-check-square',
                command: () => {
                    setTab(8);
                },
            },
        ];
    } else if (isTeacher.value) {
        return [
            {
                label: 'Στοιχεία',
                icon: 'pi pi-pencil',
                command: () => {
                    setTab(0);
                },
            },
            {
                label: 'Μαθητές',
                icon: 'pi pi-fw pi-users',
                command: () => {
                    setTab(1);
                },
            },
            {
                label: 'Πρόγραμμα',
                icon: 'pi pi-fw pi-calendar',
                command: () => {
                    setTab(3);
                },
            },
            // {
            //     label: chatLabel.value,
            //     icon: 'pi pi-fw pi-comments',
            //     command: () => {
            //         setTab(5);
            //     },
            // },
            {
                label: 'Αρχεία',
                icon: 'pi pi-fw pi-file-pdf',
                command: () => {
                    setTab(6);
                },
            },
            {
                label: 'Video',
                icon: 'pi pi-fw pi-video',
                command: () => {
                    setTab(7);
                },
            },
        ];
    }
    return [
        {
            label: 'Στοιχεία',
            icon: 'pi pi-pencil',
            command: () => {
                setTab(0);
            },
        },
        {
            label: 'Πρόγραμμα',
            icon: 'pi pi-fw pi-calendar',
            command: () => {
                setTab(3);
            },
        },
        // {
        //     label: chatLabel.value,
        //     icon: 'pi pi-fw pi-comments',
        //     command: () => {
        //         setTab(5);
        //     },
        // },
        {
            label: 'Αρχεία',
            icon: 'pi pi-fw pi-file-pdf',
            command: () => {
                setTab(6);
            },
        },
        {
            label: 'Video',
            icon: 'pi pi-fw pi-video',
            command: () => {
                setTab(7);
            },
        },
    ];
});

const isAdmin = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo?.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const tabIndex = ref(0);
const classes = ref([]);
const showTab = ref(false);
const totalRequests = ref(0);
const totalNotifications = ref(0);
const loadingRequests = ref(false);
const notifications = ref([]);
const studentClassRequest = ref(null);

const tableRequestsFilters = ref({
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    school: { value: null, matchMode: FilterMatchMode.CONTAINS },
    examPeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    studentName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    accountableID: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    isPrivate: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    category: { value: null, matchMode: FilterMatchMode.EQUALS },
    subCateg1: { value: null, matchMode: FilterMatchMode.EQUALS },
    subCateg2: { value: null, matchMode: FilterMatchMode.EQUALS },
    courseTitle: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const setTab = async (index) => {
    console.log('setTab:' + index);
    if (classInfoLoading.value) return;
    tabIndex.value = index;
    await nextTick();
    if (tabIndex.value === 0 && editMode.value) {
        await fetchClassInfo(state.id);
    } else if (tabIndex.value === 1) {
        await loadStudentsForClass();
    } else if (tabIndex.value === 2) {
        loadingRequests.value = true;
        await fetchSchoolsAndCategories();
        requests.value = [];
        let classCourses = selectedCourses.value.map((c) => c.id);
        let firstCourseId = classCourses[0];
        //initial filter requests by the first course
        let courseInfoRes = await RPCApi(RPCMethods.Courses.FetchCourseInfoById, { id: firstCourseId });
        if (courseInfoRes.status == 'ok') {
            let { courseInfo } = courseInfoRes.data.result.data;
            let categ = categories.value.find((c) => c.id === courseInfo.categoryID);
            if (categ) tableRequestsFilters.value.category.value = categ;

            tableRequestsFilters.value.subCateg1.value = courseInfo.subCateg1;
            tableRequestsFilters.value.subCateg2.value = courseInfo.subCateg2;
            tableRequestsFilters.value.courseTitle.value = { id: firstCourseId, title: courseInfo.title, semester: courseInfo.semester };
            tableRequestsFilters.value.status.value = Utils.RequestStatuses.find((s) => s.value == Utils.RequestStatus.Waiting);
        }
        onRequestsFilter();
    } else if (tabIndex.value === 3) {
        await loadLessonsForClass();
    } else if (tabIndex.value === 4) {
        await loadNotificationsForClass();
    } else if (tabIndex.value === 6) {
        if (uploader.value) uploader.value.fetchFiles(`courses/${selectedCourses.value[0].id}/files`, { classID: state.id, classTitle: state.title, courses: selectedCourses.value });
    } else if (tabIndex.value === 7) {
        if (videos.value) videos.value.fetchVideos(state.id, showFirstVideoOnly.value);
    } else if (tabIndex.value === 8) {
        console.log('fetchToDo');
    }
};

const onFetchAccountables = async () => {
    let accountablesRes = await RPCApi(RPCMethods.User.FetchAccountables);
    if (accountablesRes.status === 'ok') {
        const { users } = accountablesRes.data.result.data;
        let r = [];
        for (let user of users) {
            r.push({
                label: `${user.name}`,
                value: user.id,
            });
        }
        accountables.value = r;
    }
};

const loadCoursesRequests = async () => {
    loadingCoursesRequests.value = true;
    let res = await RPCApi(RPCMethods.Courses.FetchCoursesRequests, { examPeriod: selectedCoursesFilters.value.examPeriod.value?.value });
    if (res.status == 'ok') {
        const requestsCoursesData = res.data.result.data;
        let d = [];
        sumNumNotPosted.value = 0;

        for (let obj of requestsCoursesData) {
            if (obj.requestStatus === Utils.RequestStatus.Waiting || obj.requestStatus == null) {
                let item = {
                    id: obj.courseID,
                    category: obj.category,
                    subCateg1: obj.subCateg1,
                    subCateg2: obj.subCateg2,
                    title: obj.courseTitle,
                    semester: obj.semester,
                    requests: obj.requestsNum,
                    hours: obj.hours,
                    price: obj.price,
                    jobPostingIds: obj.jobPostingIds.length,
                    numNotPosted: obj.requestsNum - obj.jobPostingIds.length,
                };
                d.push(item);

                sumNumNotPosted.value += item.numNotPosted;
            }
        }

        let filtered = [...d];
        if (selectedCoursesFilters.value.category.value) {
            filtered = filtered.filter((c) => c.category === selectedCoursesFilters.value.category.value.name);
        }
        if (selectedCoursesFilters.value.subCateg1.value) {
            filtered = filtered.filter((c) => c.subCateg1 === selectedCoursesFilters.value.subCateg1.value);
        }
        if (selectedCoursesFilters.value.subCateg2.value) {
            filtered = filtered.filter((c) => c.subCateg2 === selectedCoursesFilters.value.subCateg2.value);
        }
        coursesRequests.value = filtered;
        totalCourses.value = coursesRequests.value.length;
    }
    loadingCoursesRequests.value = false;
};

const loadNotificationsForClass = async () => {
    loadingNotifications.value = true;
    let res = await RPCApi(RPCMethods.Notifications.FetchForClass, { id: state.id });
    if (res.status == 'ok') {
        const notificationsData = res.data.result.data;
        let d = [];

        for (let obj of notificationsData) {
            d.push({
                id: obj.id,
                title: obj.title,
                type: obj.type,
                runAt: moment(Utils.datetimeToDate(obj.runAt)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.runAt)).format('HH:mm'),
                status: obj.status,
            });
        }
        notifications.value = d;
        totalNotifications.value = d.length;
    }
    loadingNotifications.value = false;
};

const loadLessonsForClass = async () => {
    console.log('loadLessonsForClass:' + state.id);
    loadingLessons.value = true;
    lessons.value = [];
    let lessonsRes = await RPCApi(RPCMethods.Lessons.FetchLessonsForClass, { id: state.id });
    console.log(lessonsRes);
    let d = [];
    if (lessonsRes.status == 'ok') {
        let lessonsData = lessonsRes.data.result.data;
        console.log(lessonsData);
        for (let lessonID in lessonsData) {
            let lesson = lessonsData[lessonID];
            d.push({
                id: lesson.LessonID,
                startDate: Utils.datetimeToDate(lesson.LessonStartedAt),
                endDate: Utils.datetimeToDate(lesson.LessonEndedAt),
                dateRange:
                    moment(Utils.datetimeToDate(lesson.LessonStartedAt)).format('DD/MM/YYYY') +
                    ' (' +
                    moment(Utils.datetimeToDate(lesson.LessonStartedAt)).format('HH:mm') +
                    ' - ' +
                    moment(Utils.datetimeToDate(lesson.LessonEndedAt)).format('HH:mm') +
                    ')',
                date: moment(Utils.datetimeToDate(lesson.LessonStartedAt)).format('ddd DD/MM/YYYY'),
                time: moment(Utils.datetimeToDate(lesson.LessonStartedAt)).format('HH:mm') + ' - ' + moment(Utils.datetimeToDate(lesson.LessonEndedAt)).format('HH:mm'),
                classroom: lesson.ClassroomName,
                teacher: { id: lesson.TeacherID, name: lesson.TeacherName },
                attendees: lesson.Attendees,
                submitted: lesson.Attendees.length > 0 && lesson.commitedBy != null,
                commitedBy: lesson.commitedBy,
                lessonCanceled: lesson.lessonCanceled,
                zoomURL: lesson.ZoomURL,
            });
        }
    }

    d = d.sort((a, b) => {
        if (a.startDate < b.startDate) return 1;
        if (a.startDate > b.startDate) return -1;
        return 0;
    });

    lessons.value = d;
    loadingLessons.value = false;
    totalLessons.value = lessons.value.length;
};

const lessonStatus = (data) => {
    console.log('lessonStatus, data: ', data);
    let status = 'Εκκρεμεί';
    if (data.commitedBy == null) {
        status = 'Εκκρεμεί';
    } else if (data.lessonCanceled === 0) {
        status = 'Υποβλήθηκε';
    } else if (data.lessonCanceled === 1) {
        status = 'Ακυρώθηκε';
    }
    return status;
};

const isZoomLinkActive = (data) => {
    return new Date() >= data.startDate && new Date() <= data.endDate && data.commitedBy == null && !data.lessonCanceled;
};

const handleZoomClick = (data) => {
    if (isZoomLinkActive(data)) {
        openLink(data.zoomURL, data.date);
    }
};

const loadStudentsForClass = async () => {
    loadingStudents.value = true;
    students.value = [];
    let res = await RPCApi(RPCMethods.Class.FetchStudents, {
        classID: state.id,
    });

    let arr = [];
    if (res.status == 'ok') {
        const { students: studentsData } = res.data.result.data;
        if (studentsData != null) {
            let d = {};
            for (let obj of studentsData) {
                d = {
                    id: obj.id,
                    fullname: obj.studentName,
                    email: obj.email,
                    mobile: obj.mobile,
                    dues: obj.dues,
                    duesType: obj.dues < 0 ? 'negative' : obj.dues > 0 ? 'positive' : 'zero',
                    ban: obj.ban === 1 ? true : false,
                    requestID: obj.requestID,
                    status: obj.requestStatus,
                    sumHoursDuration: 0,
                    lessonID: null,
                    offer: obj.offer,
                    isPrivate: obj.classPrivate === 1,
                    classSum: 0,
                };
                arr.push(d);
            }
        }

        let resAttendances = await RPCApi(RPCMethods.Attendances.FetchAttendancesForClassLessons, {
            id: state.id,
        });
        console.log(resAttendances);
        if (resAttendances.status == 'ok') {
            const attendances = resAttendances.data.result.data;
            if (attendances != null) {
                for (let obj of attendances) {
                    let student = arr.find((s) => s.id == obj.studentID);
                    if (student) {
                        student.sumHoursDuration = obj.sumHoursDuration;
                        student.lessonID = obj.lessonID;
                    }
                }
            }
        }

        for (let obj of arr) {
            if (obj.isPrivate) {
                obj.classSum = obj.offer * obj.sumHoursDuration;
            } else {
                obj.classSum = obj.offer;
            }
        }

        students.value = arr;
    } else {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
    }

    console.log('loadStudentsForClass', res);
    loadingStudents.value = false;
};

const fetchUserClassRequests = async () => {
    console.log('fetchUserClassRequests');
    let res = await RPCApi(RPCMethods.Request.FetchUserClassRequest, {
        userID: Utils.UserInfo.id,
        classID: state.id,
    });

    if (res.status === 'ok') {
        const { request } = res.data.result.data;
        if (request) {
            studentClassRequest.value = request;
        }
    }
};

const show = async (data, onCloseCB) => {
    classInfoLoading.value = true;
    moment.locale('el');
    const { classID, forcedTabIndex } = data || {};
    closeCB.value = onCloseCB;
    console.log(classID);
    classes.value = [];
    studentClassRequest.value = null;
    totalSelectedRequests.value = null;
    tabIndex.value = 0;
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = !!classID;

    headerTitle.value = editMode.value ? `Επεξεργασία Τμήματος (${classID})` : 'Νέο Τμήμα';
    showTab.value = classID != null;
    state.id = classID;
    state.status = Utils.ClassStatuses.find((s) => s.value == Utils.ClassStatus.Active);

    if (isStudent.value) {
        await fetchUserClassRequests();
    }

    await onFetchAccountables();

    let examPeriodsRes = await RPCApi(RPCMethods.Periods.Fetch);
    if (examPeriodsRes.status === 'ok') {
        const { periods } = examPeriodsRes.data.result.data;
        let r = [];
        for (let period of periods) {
            if (period.active === 1)
                r.push({
                    label: `${period.title}`,
                    value: period.id,
                });
        }
        examPeriods.value = r;
    }

    let classroomsRes = await RPCApi(RPCMethods.Classrooms.Fetch);
    if (classroomsRes.status === 'ok') {
        const { classrooms: classroomsData } = classroomsRes.data.result.data;
        let c = [];
        for (let classroom of classroomsData) {
            c.push({
                label: `${classroom.name}`,
                value: classroom.id,
            });
        }
        classrooms.value = c;
    }

    let res = await RPCApi(RPCMethods.Courses.Fetch);
    if (res.status !== 'ok') {
        filteredCourses.value = [];
        return;
    }

    const { coursesData } = res.data.result.data;
    let d = [];
    for (let obj of coursesData) {
        d.push({
            id: obj.id,
            title: obj.title,
            categoryName: obj.categoryName,
            subCateg1: obj.subCateg1,
            subCateg2: obj.subCateg2,
            semester: obj.semester,
            price: obj.price,
        });
    }
    filteredCourses.value = d;

    await fetchSchoolsAndCategories();

    await loadCoursesRequests();

    if (editMode.value) {
        await fetchClassInfo(classID);
    } else {
        //if data passed contains the info about the course, then we need to set the course filters
        if (data && data.courseInfo && data.requests) {
            let course = filteredCourses.value.find((c) => c.id === data.courseInfo.id);
            console.warn('course info:', course);

            selectedCoursesFilters.value['category'].value = categories.value.find((c) => c.name === course.categoryName);
            selectedCoursesFilters.value['subCateg1'].value = course.subCateg1;
            selectedCoursesFilters.value['subCateg2'].value = course.subCateg2;
            selectedCoursesFilters.value['examPeriod'].value = examPeriods.value.find((p) => p.value === data.courseInfo.semester);
            state.examPeriod = selectedCoursesFilters.value['examPeriod'].value;

            await onCoursesFilter();
            selectedCourses.value = [course];
            totalSelectedRequests.value = {};
            totalSelectedRequests.value[data.courseInfo.id] = data.requests;
        }
    }
    classInfoLoading.value = false;

    if (forcedTabIndex) {
        setTab(forcedTabIndex);
    }
};

const fetchSchoolsAndCategories = async () => {
    let schoolsRes = await RPCApi(RPCMethods.Schools.Fetch);
    if (schoolsRes.status == 'ok') {
        schools.value = schoolsRes.data.result.data.schools;
        categories.value = schoolsRes.data.result.data.categories;
    }
};

const fetchClassInfo = async (classID) => {
    let classRes = await RPCApi(RPCMethods.Class.GetClassInfoById, { id: classID });
    console.log(classRes);
    if (classRes.status == 'ok') {
        const {
            classTitle,
            startDate,
            teacherId,
            teacherName,
            teacherEmail,
            phaseTotal,
            examsPeriodTitle,
            examsPeriodId,
            status,
            comments,
            isPrivate,
            classroomId,
            accountable,
            price,
            onSite,
            firstLesson,
            allowNotifications,
            maxVideosViewsNum,
        } = classRes.data.result.data.class;
        const { courses: classCourses, phaseCurrent } = classRes.data.result.data;
        let s = filteredCourses.value.filter((course) => {
            return classCourses.find((x) => x.courseID === course.id);
        });
        let classCoursesData = [];
        for (let i = 0; i < s.length; i++) {
            classCoursesData.push({
                id: s[i].id,
                title: s[i].title,
                categoryName: s[i].categoryName,
                subCateg1: s[i].subCateg1,
                subCateg2: s[i].subCateg2,
                semester: s[i].semester,
                price: s[i].price,
            });
        }
        console.log('classCoursesData:::', classCoursesData);
        selectedCourses.value = classCoursesData;
        state.firstLesson = firstLesson ? moment(Utils.datetimeToDate(firstLesson)).format('ddd DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(firstLesson)).format('HH:mm') : '--';
        state.title = classTitle;
        state.startDate = Utils.datetimeToDate(startDate);
        state.teacher = { id: teacherId, name: teacherName, email: teacherEmail };
        state.phaseCurrent = phaseCurrent;
        state.phaseTotal = phaseTotal;
        state.examPeriod = { label: examsPeriodTitle, value: examsPeriodId };
        state.classPrice = price;
        state.status = Utils.ClassStatuses.find((s) => s.value == status);
        state.comments = comments;
        state.type = Utils.Types[isPrivate];
        state.classroom = { label: classrooms.value.find((c) => c.value == classroomId).label, value: classroomId };
        state.accountable = accountables.value.find((a) => a.value == accountable);
        state.onSite = onSite == 1 ? true : false;
        state.allowNotifications = allowNotifications == 1 ? true : false;
        state.maxVideosViewsNum = maxVideosViewsNum;

        if (!isAdminOrOffice.value) {
            headerTitle.value = `${classTitle}`;
        } else {
            headerTitle.value = `(${classID}) ${classTitle} (Ώρες: ${phaseTotal}, Τιμή: ${Utils.toEUR(price)})` + (state.firstLesson ? ' (' + state.firstLesson + ')' : '');
        }
    }
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    console.log(v$);

    if (selectedCourses.value.length === 0) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσθέστε τουλάχιστον ένα μάθημα!', life: 3000 });
        return;
    }

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;

    let res;
    let payload = {
        title: state.title,
        startDate: state.startDate,
        teacherId: state.teacher.id,
        phaseTotal: state.phaseTotal,
        examsPeriodId: state.examPeriod.value,
        status: state.status.value,
        comments: state.comments,
        price: state.classPrice,
        isPrivate: state.type.value,
        classroomId: state.classroom.value,
        courseIds: selectedCourses.value.map((c) => c.id),
        accountable: state.accountable?.value,
        requests: [],
        onSite: state.onSite,
        allowNotifications: state.allowNotifications,
        maxVideosViewsNum: state.maxVideosViewsNum,
    };

    const commitSend = async () => {
        if (res.status !== 'ok') {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Παρακαλώ προσπαθήστε αργότερα', life: 3000 });
            loading.value = false;
            return;
        }
        toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

        loading.value = false;
        onHide(true);
    };

    if (editMode.value) {
        payload.id = state.id;
        res = await RPCApi(RPCMethods.Class.Update, payload);

        await commitSend();
    } else {
        if (totalSelectedRequests.value != null) {
            let requestsToAdd = [];
            for (let courseID in totalSelectedRequests.value) {
                for (let reqID of totalSelectedRequests.value[courseID]) {
                    requestsToAdd.push(reqID);
                }
            }

            payload.requests = requestsToAdd;
        }
        res = await RPCApi(RPCMethods.Class.Add, payload);
        await commitSend();
    }
};
const resetForm = () => {
    for (let field in state) state[field] = null;
    state.allowNotifications = true;
    selectedCourses.value = [];
    freshSelectedCourses.value = [];

    studentsTableFilters.value = {
        lname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    };

    submitted.value = false;
    loading.value = false;
};

const searchTeachers = useDebounceFn(async (event) => {
    if (event.query.trim().length === 0) {
        filteredTeachers.value = [];
        return;
    }

    console.log('searchTeachers: ' + event.query);
    let res = await RPCApi(RPCMethods.User.FetchTeachersByName, { name: event.query.trim() });
    if (res.status !== 'ok') {
        filteredTeachers.value = [];
        return;
    }

    const { teachers } = res.data.result.data;
    let r = [];
    for (let teacher of teachers) {
        r.push({
            id: teacher.id,
            name: teacher.name,
            email: teacher.email,
            mobile: teacher.mobile,
            comments: teacher.comments,
            school: teacher.school_id,
            ban: teacher.ban,
            dues: 0,
        });
    }

    filteredTeachers.value = r.filter((t) => t.ban !== 1);

    console.log(filteredTeachers.value);
}, 300);

const teacherLblFunction = (teacher) => {
    if (teacher == null || teacher.email == null) return '';
    return `${teacher.name}`;
};

const courseTitleLblFunction = (course) => {
    if (course == null || course.title == null) return '';
    if (course.semester == null) return course.title + ' (' + course.subCateg2 + ' - ' + course.subCateg1 + ')';
    return course.title + ' (' + course.semester + ') (' + course.subCateg2 + ' - ' + course.subCateg1 + ')';
};

const searchCourses = useDebounceFn(async (event) => {
    console.log('searchCourses: ' + event.query);

    if (!event.query.trim().length) {
        coursesOptions.value = [...filteredCourses.value];
        return;
    }
    coursesOptions.value = filteredCourses.value.filter((d) => {
        return d.title.toLowerCase().startsWith(event.query.toLowerCase());
    });
}, 300);

const showRequestDialog = (requestID) => {
    console.log('show request dialog for id:' + requestID);
    popupStore.show(Utils.Popups.RequestDialog, { requestID, clsID: state.id }, (action) => {
        console.log('request dialog closed with action: ' + action);
    });
};

const onRequestRemove = (id) => {
    console.log('onRequestRemove: ' + id);
    confirm.require({
        message: `Είστε σίγουροι ότι θέλετε να διακόψετε τον μαθητή από το τμήμα;`,
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ναι',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-primary',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            let reqRes = await RPCApi(RPCMethods.Request.GetRequestInfoById, { id });
            if (reqRes.status == 'ok') {
                let requestInfo = reqRes.data.result.data;
                requestInfo.id = id;
                requestInfo.classId = state.id;
                requestInfo.examPeriod = requestInfo.examPeriodId;
                requestInfo.status = Utils.RequestStatus.Stopped;
                let res = await RPCApi(RPCMethods.Request.Update, { ...requestInfo });
                if (res.status == 'ok') {
                    toast.add({ severity: 'success', summary: 'Success', detail: `Η Αίτηση με ID '${id}' άλλαξε σε '${Utils.RequestStatusToString(Utils.RequestStatus.Stopped)}'!`, life: 3000 });
                } else {
                    toast.add({ severity: 'error', summary: 'Σφάλμα', detail: `Σφάλμα κατά την διακοπή της Αίτησης με ID '${id}'!`, life: 3000 });
                }
                loadStudentsForClass();
            }
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const showStudentDialog = (studentID) => {
    popupStore.show(Utils.Popups.StudentDialog, { studentID }, (action) => {
        console.log('onStudentsDialogClosed:' + action);
        loadStudentsForClass();
    });
};

const onCourseRequestsDialogClosed = (action, selectedRequests, courseID) => {
    console.log('selectedRequests:', selectedRequests);
    if (!action) return;
    totalSelectedRequests.value = { ...totalSelectedRequests.value };
    totalSelectedRequests.value[courseID] = selectedRequests;
    console.log('totalSelectedRequests:', totalSelectedRequests.value);
};

const totalStudents = computed(() => {
    return students.value.filter((s) => s.status === Utils.RequestStatus.OnClass).length;
});

const totalSelectedRequestsNum = computed(
    () => {
        let r = 0;
        for (let courseID in totalSelectedRequests.value) {
            r += totalSelectedRequests.value[courseID].length;
        }
        return r;
    },
    { deep: true }
);

const fetchRequests = async () => {
    loadingRequests.value = true;
    requests.value = [];
    RPCApi(RPCMethods.Request.FetchRequests, fetchRequestsParams.value).then((res) => {
        console.log(res);
        let d = [];
        if (res.status == 'ok') {
            const { total, requests: requestsData } = res.data.result.data;
            totalRequests.value = total;

            for (let obj of requestsData) {
                d.push({
                    id: obj.requestID,
                    title: obj.title,
                    school: obj.school,
                    studentName: obj.studentName,
                    studentID: obj.studentID,
                    examsPeriodTitle: obj.examsPeriodTitle,
                    accountableName: obj.accountableName,
                    accountableID: obj.accountableID,
                    isPrivate: obj.isPrivate === 1 ? true : false,
                    status: obj.status,
                });
            }
            requests.value = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
        loadingRequests.value = false;
    });
};

const onRequestsPage = useDebounceFn((event) => {
    console.log('onPage', event);
    fetchRequestsParams.value.paging = { offset: event.first, limit: requestsRowsPerPage };
    fetchRequests();
}, 300);

const onRequestsSort = useDebounceFn((event) => {
    console.log('onSort', event);
    if (event.sortField === 'fullname') event.sortField = 'lname';
    fetchRequestsParams.value.paging = { offset: 0, limit: requestsRowsPerPage };
    fetchRequestsParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetchRequests();
}, 300);

const onRequestsFilter = useDebounceFn(() => {
    console.log('onFilter', tableRequestsFilters.value);
    fetchRequestsParams.value.paging = { offset: 0, limit: requestsRowsPerPage };
    fetchRequestsParams.value.filters = {
        title: tableRequestsFilters.value.title,
        examsPeriodTitle: { value: tableRequestsFilters.value.examPeriod.value?.label, matchMode: FilterMatchMode.EQUALS },
        accountableID: { value: tableRequestsFilters.value.accountableID.value?.value, matchMode: FilterMatchMode.EQUALS },
        isPrivate: { value: tableRequestsFilters.value.isPrivate.value?.value, matchMode: FilterMatchMode.EQUALS },
        status: { value: tableRequestsFilters.value.status.value?.value, matchMode: FilterMatchMode.EQUALS },
        category: { value: tableRequestsFilters.value.category.value?.id, matchMode: FilterMatchMode.EQUALS },
        subCateg1: { value: tableRequestsFilters.value.subCateg1.value, matchMode: FilterMatchMode.EQUALS },
        subCateg2: { value: tableRequestsFilters.value.subCateg2.value, matchMode: FilterMatchMode.EQUALS },
        courseID: { value: tableRequestsFilters.value.courseTitle.value?.id, matchMode: FilterMatchMode.EQUALS },
    };
    fetchRequests();
}, 1000);

const addRequest = () => {
    console.log('addRequest');
    let firstCourseID = selectedCourses.value[0].id;
    popupStore.show(Utils.Popups.RequestDialog, { courseID: firstCourseID }, (action) => {
        console.log('onRequestDialogClosed:' + action);
        if (action) {
            if (tabIndex.value === 1) loadStudentsForClass();
            else if (tabIndex.value === 2) fetchRequests();
        }
    });
};

const onCategorySelect = (event, param) => {
    console.log('onCategorySelect: ', event.value, param);
    tableRequestsFilters.value.subCateg1.value = null;
    tableRequestsFilters.value.subCateg2.value = null;
    filteredSubCategories1.value = [];
    filteredSubCategories2.value = [];
    filteredCourses.value = [];

    if (param === 'course') {
        onCoursesFilter();
    } else {
        onRequestsFilter();
    }
};

const onSubCateg1Select = (event, param) => {
    console.log('onSubCateg1Select: ', event.value, param);
    tableRequestsFilters.value.subCateg1.value = event.value;
    tableRequestsFilters.value.subCateg2.value = null;
    filteredSubCategories2.value = [];
    filteredCourses.value = [];

    if (param === 'course') {
        onCoursesFilter();
    } else {
        onRequestsFilter();
    }
};

const onSubCateg2Select = (event, param) => {
    console.log('onSubCateg2Select: ', event.value, param);
    tableRequestsFilters.value.subCateg2.value = event.value;
    filteredCourses.value = [];

    if (param === 'course') {
        onCoursesFilter();
    } else {
        onRequestsFilter();
    }
};

const onCourseTitleSelect = (event) => {
    console.log('onCourseTitleSelect: ', event.value);
    tableRequestsFilters.value.courseTitle.value = event.value;

    onRequestsFilter();
};

const courseRequestsTitleLblFunction = (course) => {
    if (course == null || course.title == null) return '';
    if (course.semester == null) return course.title;
    return course.title + ' (' + course.semester + ')';
};

const resetGlobalFilters = (param) => {
    if (param === 'course') {
        selectedCoursesFilters.value.category.value = null;
        selectedCoursesFilters.value.subCateg1.value = null;
        selectedCoursesFilters.value.subCateg2.value = null;
        selectedCoursesFilters.value.courseTitle.value = null;
    } else {
        tableRequestsFilters.value.category.value = null;
        tableRequestsFilters.value.subCateg1.value = null;
        tableRequestsFilters.value.subCateg2.value = null;
        tableRequestsFilters.value.courseTitle.value = null;
    }

    filteredSubCategories1.value = [];
    filteredSubCategories2.value = [];
    filteredCourses.value = [];
    if (param === 'course') {
        onCoursesFilter();
    } else {
        onRequestsFilter();
    }
};

const searchCategory = async (event, param) => {
    console.log('searchCategory: ' + event.query, param);

    if (!event.query.trim().length) {
        filteredCategories.value = [...categories.value];
    } else {
        filteredCategories.value = categories.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

const searchSubCategory1 = async (event, param) => {
    console.log('searchSubCategory1: ' + event.query, param);
    let categoryValue = param === 'course' ? selectedCoursesFilters.value.category.value : tableRequestsFilters.value.category.value;
    if (categoryValue == null) {
        filteredSubCategories1.value = [];
        return;
    }

    filteredSubCategories1.value = schools.value.filter((d) => {
        return d.category === categoryValue.name;
    });

    if (!event.query.trim().length) {
        filteredSubCategories1.value = [...filteredSubCategories1.value];
    } else {
        filteredSubCategories1.value = filteredSubCategories1.value.filter((d) => {
            return d.subCateg1.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories1.value = [...new Set(filteredSubCategories1.value.map((d) => d.subCateg1))];
};

const searchSubCategory2 = async (event, param) => {
    console.log('searchSubCategory2: ' + event.quer, param);

    let subCateg1Value = param === 'course' ? selectedCoursesFilters.value.subCateg1.value : tableRequestsFilters.value.subCateg1.value;
    if (subCateg1Value == null) {
        filteredSubCategories2.value = [];
        return;
    }

    filteredSubCategories2.value = schools.value.filter((d) => {
        return d.subCateg1 === subCateg1Value;
    });

    if (!event.query.trim().length) {
        filteredSubCategories2.value = [...filteredSubCategories2.value];
    } else {
        filteredSubCategories2.value = filteredSubCategories2.value.filter((d) => {
            return d.subCateg2.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories2.value = [...new Set(filteredSubCategories2.value.map((d) => d.subCateg2))];
};

const searchRequestsCourses = useDebounceFn(async (event) => {
    console.log('searchCourses: ' + event.query);
    if (tableRequestsFilters.value.category.value == null || tableRequestsFilters.value.subCateg1.value == null || tableRequestsFilters.value.subCateg2.value == null) {
        filteredCourses.value = [];
        return;
    }

    let school = schools.value.find(
        (s) => s.category === tableRequestsFilters.value.category.value.name && s.subCateg1 === tableRequestsFilters.value.subCateg1.value && s.subCateg2 === tableRequestsFilters.value.subCateg2.value
    );
    if (school == null) {
        filteredCourses.value = [];
        return;
    }
    let res = await RPCApi(RPCMethods.Courses.FetchCoursesForSchool, { title: event.query.trim(), schoolID: school.id });
    if (res.status !== 'ok') {
        filteredCourses.value = [];
        return;
    }

    console.log('FetchCoursesForSchool:', res);

    filteredCourses.value = res.data.result.data;
}, 300);

const cloneRequest = (id) => {
    console.log('CLONE REQUEST WITH ID:' + id);
    popupStore.show(Utils.Popups.RequestDialog, { requestID: id, clone: true }, (action) => {
        console.log('onRequestDialogClosed:' + action);
        if (action) {
            if (tabIndex.value === 1) loadStudentsForClass();
            else if (tabIndex.value === 2) fetchRequests();
        }
    });
};

const addRequestToClass = async (id) => {
    console.log('ADD REQUEST TO CLASS WITH ID:' + id);
    confirm.require({
        message: `Είστε σίγουροι ότι θέλετε να εντάξετε την αίτηση του μαθητή στο τμήμα;`,
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ναι',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-primary',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            let reqRes = await RPCApi(RPCMethods.Request.GetRequestInfoById, { id });
            if (reqRes.status == 'ok') {
                let requestInfo = reqRes.data.result.data;
                requestInfo.id = id;
                requestInfo.classId = state.id;
                requestInfo.examPeriod = requestInfo.examPeriodId;
                requestInfo.status = Utils.RequestStatus.Posted;
                let res = await RPCApi(RPCMethods.Request.Update, { ...requestInfo });
                if (res.status == 'ok') {
                    toast.add({ severity: 'success', summary: 'Success', detail: `Η Αίτηση με ID '${id}' προστέθηκε στο τμήμα!`, life: 3000 });
                } else {
                    toast.add({ severity: 'error', summary: 'Σφάλμα', detail: `Σφάλμα κατά την προσθήκη της Αίτησης με ID '${id}'' στο τμήμα!`, life: 3000 });
                }
                onRequestsFilter();
            }
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const addLesson = () => {
    console.log(state);
    if (state.status.value === Utils.ClassStatus.Completed) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Το τμήμα Ολοκληρώθηκε!', life: 3000 });
        return;
    }

    popupStore.show(Utils.Popups.LessonDialog, { classID: state.id, teacherID: state.teacher.id }, (action, payload) => {
        if (action) {
            loadLessonsForClass();
        }
    });
};

const editLessonDialog = (data) => {
    console.log('EDIT LESSON DIALOG :', data);
    popupStore.show(Utils.Popups.LessonDialog, { lessonID: data.id, teacherID: data.teacher.id, classID: state.id, zoomURL: data.zoomURL, attendancesSubmited: data.submitted }, (action, payload) => {
        console.log('onLessonDialogClosed:' + action);
        if (action) loadLessonsForClass();
        if (payload) {
            popupStore.show(
                Utils.Popups.NotificationDialog,
                {
                    classID: state.id,
                    lessonTitle: selectedCourses.value[0].title,
                    teacherName: state.teacher.name,
                    classTitle: state.title,
                    lessonStartAt: payload.startedAt,
                    lessonID: payload.lessonID,
                    zoomMeetingURL: data.zoomURL,
                },
                (action) => {
                    if (action) loadNotificationsForClass();
                }
            );
        }
    });
};

const addNotification = () => {
    console.log('ADD NOTIFICATION IN CLASS:');
    popupStore.show(Utils.Popups.NotificationDialog, { classID: state.id, teacherName: state.teacher.name, classTitle: state.title, classPrice: state.classPrice }, (action) => {
        console.log('onNotificationDialogClosed:' + action);
        if (action) loadNotificationsForClass();
    });
};

const showDeleteLessonDialog = (id) => {
    console.log('SHOW DELETE LESSON DIALOG WITH ID:' + id);
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το μάθημα;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.Lessons.DeleteLessonForClass, { id });
            loadLessonsForClass();
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const showAttendancesDialog = (data) => {
    console.log('Show attendances dialog for:', data);
    console.log('state.type:', state.type);
    popupStore.show(Utils.Popups.AttendancesDialog, { ...data, classTitle: state.title, isPrivate: state.type.value === 1 }, (action) => {
        console.log('onAttendancesDialogClosed:' + action);
        if (action) loadLessonsForClass();
    });
};

const openLink = (url, date) => {
    console.log('OPEN ZOOM VIDEO DIALOG USING SDK:' + url, date);
    //check if date is not today
    let today = moment().format('DD/MM/YYYY');
    if (date !== today) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Ο σύνδεσμος είναι ενεργός την ημέρα του μαθήματος!', life: 3000 });
        return;
    }

    window.open(url, '_blank');
};

const showStudentAttendancesDialog = (data) => {
    console.log('Show student attendances dialog for student with id:', data);
    popupStore.show(Utils.Popups.StudentAttendancesDialog, { ...data }, (action) => {
        console.log('onStudentAttendancesDialogClosed:' + action);
    });
};

const editNotificationDialog = (data) => {
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, classID: state.id, classTitle: state.title }, (action) => {
        console.log('onNotificationDialogClosed:' + action);
        if (action) loadNotificationsForClass();
    });
};

const cloneNotification = (data) => {
    console.log('CLONE NOTIFICATION WITH ID:', data);
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, classID: state.id, classTitle: state.title, clone: true }, (action) => {
        console.log('onNotificationDialogClosed:' + action);
        if (action) loadNotificationsForClass();
    });
};

const showNotificationDialog = (data) => {
    popupStore.show(Utils.Popups.NotificationDialog, { notificationID: data.id, classID: state.id, classTitle: state.title, showOnly: true }, (action) => {});
};

const showDeleteNotificationDialog = (id) => {
    console.log('SHOW DELETE NOTIFICATION DIALOG WITH ID:' + id);
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το mail;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.Notifications.Delete, { id });
            loadNotificationsForClass();
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const onCourseRowSelected = (event) => {
    console.log('onCourseRowSelected: ', event.data);
    state.phaseTotal = selectedCourses.value[0].hours;
    state.classPrice = selectedCourses.value[0].price;
};

const onCourseRowUnselected = (event) => {
    console.log('onCourseRowUnselected: ', event.data);
    if (selectedCourses.value.length === 0) {
        state.phaseTotal = null;
        state.classPrice = null;
    }
};

const filterCoursesByExamPeriod = (event) => {
    console.log('filterCoursesByExamPeriod: ', event.value);
    loadCoursesRequests();
};

const onCoursesFilter = () => {
    console.log('onCoursesFilter', selectedCoursesFilters.value);
    selectedCourses.value = [];
    loadCoursesRequests();
};

const showCoursesRequestsDialog = (courseID) => {
    console.log('show all requests for course with id: ' + courseID);
    let examPeriod = selectedCoursesFilters.value['examPeriod'].value ? selectedCoursesFilters.value['examPeriod'].value.value : null;
    popupStore.show(
        Utils.Popups.CourseRequestsDialog,
        {
            courseID,
            totalSelectedRequests: totalSelectedRequests.value ? totalSelectedRequests.value[courseID] : [],
            examPeriod: examPeriod,
        },
        (action, selectedRequests, courseID) => {
            onCourseRequestsDialogClosed(action, selectedRequests, courseID);
        }
    );
};

const showOfferDialog = async (requestID) => {
    let classTitle = state.title;
    console.log('showOfferDialog FOR requestID:' + requestID + ' classTitle: ' + classTitle);

    popupStore.show(Utils.Popups.OfferDialog, { id: requestID, classTitle }, (action) => {
        console.log('OfferDialog closed:' + action);
        loadStudentsForClass();
    });
};

defineExpose({
    show,
});

const showCourseDialog = (courseID) => {
    console.log('showCourseDialog for courseID: ' + courseID);
    popupStore.show(Utils.Popups.CourseDialog, courseID, (action) => {
        console.log('CourseDialog closed:' + action);
        loadCoursesRequests();
    });
};

const deleteClassData = async () => {
    console.log('deleteClassData');
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το Chat και τα Video από το τμήμα;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!' + state.id);
            let res = await RPCApi(RPCMethods.Class.DeleteData, { classID: state.id });
            if (res.status === 'ok') {
                toast.add({ severity: 'success', summary: 'Επιτυχία', detail: `Τα δεδομένα διαγράφηκαν επιτυχώς!`, life: 3000 });
                onHide(true);
            } else {
                toast.add({ severity: 'error', summary: 'Σφάλμα', detail: `Σφάλμα κατά την διαγραφή του τμήματος!`, life: 3000 });
            }
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const openRelatedCoursesDialog = () => {
    console.log('Open related courses dialog');
    coursesDialogShown.value = true;
};

const onCoursesSelection = (event) => {
    if (freshSelectedCourses.value.length === 0) {
        return;
    }
    selectedCourses.value = freshSelectedCourses.value;
    coursesDialogShown.value = false;
    console.log('selectedCourses.value:', selectedCourses.value);
};

const onSelectedCoursesChanged = (data) => {
    console.log('onSelectedCoursesChanged:', data);
    if (!data || data.length === 0) {
        freshSelectedCourses.value = [];
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσθέστε τουλάχιστον ένα μάθημα!', life: 3000 });
        return;
    }
    freshSelectedCourses.value = data;
};
</script>

<style lang="scss" scoped>
.fixed-tab-menu {
    position: sticky;
    top: 0;
    z-index: 1;
}

.zoom-play-icon {
    background-image: url('@/assets/images/playZoom.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 4rem;
    width: 4rem;
    cursor: pointer;
}

.zoom-lbl-icon {
    background-image: url('@/assets/images/zoomIcon1.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 25px;
    width: 90px;
    cursor: pointer;
}

.inactive-zoom-link {
    opacity: 0.3;
    cursor: not-allowed;
}

.box {
    border-radius: 0.75rem;
    border: 2px solid #e3e8ef;
    background: #fff;
}
</style>
