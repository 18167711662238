import moment from 'moment-timezone';
import el from 'moment/locale/el'; // Import the Greek locale data directly
import Utils from './Utils';

export default () => {
    // Register and set the Greek locale
    moment.locale('el', el);

    // Create a mapping for Greek months
    const greekMonths = {
        January: 'Ιανουαρίου',
        February: 'Φεβρουαρίου',
        March: 'Μαρτίου',
        April: 'Απριλίου',
        May: 'Μαΐου',
        June: 'Ιουνίου',
        July: 'Ιουλίου',
        August: 'Αυγούστου',
        September: 'Σεπτεμβρίου',
        October: 'Οκτωβρίου',
        November: 'Νοεμβρίου',
        December: 'Δεκεμβρίου',
    };

    const convertToEUR = (value) => {
        if (value == null) return '0,00 €';
        return value.toLocaleString('el-GR', { style: 'currency', currency: 'EUR' });
    };

    const PaymentGatewayMode2 = `
        <p>
        <span style="color: rgb(0, 128, 0)"><u>ΤΡΟΠΟΙ ΚΑΤΑΒΟΛΗΣ ΔΙΔΑΚΤΡΩΝ:</u></span>
        <br/>
        Μπορείτε να προχωρήσετε στην αποπληρωμή των διδάκτρων σας:
        <ul>
            <li><u><b>στο ταμείο</b> σε ένα από τα εκπαιδευτήρια μας</u> <i>(Σύνταγμα, Πειραιά)</i></li>
            <li><b>ή με Τραπεζική κατάθεση</b> σε λογαριασμό του Φροντιστηρίου:</li>
        </ul>
        <br/>
        </p>
        <p>
        <b>ALPHA BANK</b>
        <ul>
            <li>IBAN : GR80 0140 1150 1150 0233 0000 360</li>
            <li>Δικαιούχος :  <b>Χ.ΒΙΤΑΛΗ & ΣΙΑ ΟΕ / ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ</b></li>
        </ul>
        </p>
        <br/><br/>
        <p>
        <b>Τράπεζα Πειραιώς - <u><i> Για όσους καταθέτουν <span style="color: rgb(230, 0, 0)">από</span> Τράπεζα Πειραιώς <span style="color: rgb(230, 0, 0)">(και μόνο)</span></u></i></b>
        <ul>
            <li>IBAN : GR32 0172 0230 0050 2310 2707 093</li>
            <li>Δικαιούχος :  <b>Χ.ΒΙΤΑΛΗ & ΣΙΑ ΟΕ / ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ</b></li>
        </ul>
        </p>
        <br/><br/>
        <p>
        Στο πεδίο: <b>ΔΙΚΑΙΟΛΟΓΙΑ-ΑΙΤΙΟΛΟΓΙΑ ΠΡΟΣ ΔΙΚΑΙΟΥΧΟ-ΠΛΗΡΟΦΟΡΙΕΣ</b> πρέπει να αναγράφετε <u><span style="color: rgb(0, 0, 230)">το ονοματεπώνυμο του φοιτητή.</span></u>
        </p>
        <br/><br/>
        <p>
        Το <b>ΚΑΤΑΘΕΤΗΡΙΟ</b> θα μας το στείλετε στο email : <span style="color: rgb(0, 0, 230)">info@vitali.gr</span> ώστε να πιστώσουμε άμεσα την καρτέλα σας.
        </p>
        <br/><br/>
        Παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση. 
    `;

    const BankPaymentGateways = `
        <b>Τρόποι καταβολής των διδάκτρων</b>
        <br/>
        <br/>
        Τα δίδακτρα μπορείτε να τα καταβάλετε:
        <ul>
            <li>Στο ταμείο σε ένα από τα εκπαιδευτήρια μας (Αθήνα, Πειραιάς)</li>
            <li>Με τραπεζική κατάθεση σε λογαριασμό του φροντιστηρίου (ALPHA bank, τράπεζα Πειραιώς)</li>
            <li>Μέσω πιστωτικής κάρτας (POS ή ePOS)</li>
        </ul>
    `;

    const WelcomeInvitation = (isStudent, alreadyLoggedIn, email) => {
        console.warn('WelcomeInvitation:' + isStudent + ' alreadyLoggedIn? ' + alreadyLoggedIn + ' email:' + email);
        moment.locale('el');
        let title = `Ενεργοποίηση λογαριασμού στην διαδικτυακή εφαρμογή Vitali WebApp`;
        let body = '';
        if (isStudent) {
            body = `
                Καλησπέρα σας,
                <br>
                <br>
                Το email αυτό αποτελεί την πρόσκληση για την ενεργοποίηση του λογαριασμού σας στην διαδικτυακή εφαρμογή <a href='https://webapp.vitali.gr'>Vitali WebApp</a>
                <br>
                <br>
                Η ενεργοποίηση του λογαριασμού σας στην διαδικτυακή εφαρμογή είναι <b>υποχρεωτική</b> ώστε να μπορέσετε να συμμετέχετε στα online μαθήματα και να έχετε πρόσβαση στις σημειώσεις, βίντεο και στο υποστηρικτικό υλικό των μαθημάτων σας. 
                <br>
                <br>
                Για να συνδεθείτε στην διαδικτυακή εφαρμογή πατήστε εδώ <a href='https://webapp.vitali.gr'>Vitali WebApp</a>.
                <br>
                <br>
                Το όνομα χρήστη είναι το email που μας έχετε δηλώσει <b>(User Name) : ${email}</b>.
                <br>
                <br>
            `;
        } else {
            body = `
                Καλησπέρα σας,
                <br>
                <br>
                Το email αυτό αποτελεί την πρόσκληση για την ενεργοποίηση του λογαριασμού σας στην διαδικτυακή εφαρμογή <a href='https://webapp.vitali.gr'>Vitali WebApp</a>
                <br>
                <br>
                Η ενεργοποίηση του λογαριασμού σας στην διαδικτυακή εφαρμογή είναι <b>υποχρεωτική</b>. 
                <br>
                <br>
                Για να συνδεθείτε στην διαδικτυακή εφαρμογή πατήστε εδώ <a href='https://webapp.vitali.gr'>Vitali WebApp</a>.
                <br>
                <br>
                Το όνομα χρήστη είναι το email που μας έχετε δηλώσει <b>(User Name) : ${email}</b>.
                <br>
                <br>
            `;
        }

        if (!alreadyLoggedIn) {
            body += `
            Ο <b>κωδικός</b> σας για να εισέλθετε στην εφαρμογή είναι : __temp_credentials__
            <br>
            <br>
            Ο κωδικός έχει δημιουργηθεί αυτόματα και είναι μοναδικός. Αν θέλετε μπορείτε να τον αλλάξετε απλά πατώντας πάνω στο "Αλλαγή/Υπενθύμιση κωδικού". 
            <br>
            <br>
            `;
        }
        body += `
            Αναλυτικές οδηγίες για την ενεργοποίηση του λογαριασμού μπορείτε να βρείτε εδώ : <a href='https://vitali.gr/vitali-webapp/login-vitali-webapp/'>Οδηγίες</a>
            <br>
            <br>
        `;

        return { title, body };
    };

    const EconomicsNotification = () => {
        moment.locale('el');
        let title = `Ενημέρωση Οικονομικών`;
        let body = `
        Καλησπέρα σας,
        <br/><br/>
        <p>
        Σας ενημερώνουμε ότι μετά από τον έλεγχο της οικονομικής σας καρτέλας εμφανίζεται υπόλοιπο διδάκτρων για τα μαθήματα που έχετε παρακολουθήσει.
        </p>
        <br/>
        <p>
        <b>Μέσω της πλατφόρμας</b> <a href = "https://vitali.gr/vitali-webapp/">https://vitali.gr/vitali-webapp</a> <b>έχετε τη δυνατότητα δημιουργίας λογαριασμού και προβολής της οικονομικής σας καρτέλας </b> (χρεώσεις μαθημάτων, υπόλοιπο), καθώς και τις συνολικές ώρες που έχουν ολοκληρωθεί ανά μάθημα.
        </p>
        <br/>
        <p>
        <b>Παρακαλούμε για την άμεση τακτοποίηση των διδάκτρων σας και παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση.</b>
        </p>
        `;

        return { title, body };
    };

    const StudentsCompletedLessons = (remainingDues) => {
        moment.locale('el');
        let title = `Ενημέρωση διδάκτρων`;
        let body = `
        [αυτοματοποιημένο μήνυμα - λογιστήριο]
        <br/>
        <br/>
        Καλησπέρα σας,
        <br/>
        <br/>
        Σας ενημερώνουμε πως για τα μαθήματα που έχετε παρακολουθήσει εμφανίζεται υπόλοιπο διδάκτρων <b>${remainingDues}</b>
        <br/>
        <br/>
        Επειδή τα μαθήματα σας <b>έχουν ήδη ολοκληρωθεί</b> θα πρέπει να προβείτε σε τακτοποίηση των διδάκτρων σας.
        <br/>
        <br/>
        Για την άμεση τακτοποίηση των διδάκτρων σας παρακαλούμε επικοινωνήστε μαζί μας.
        <br/>
        <br/>
        Μέσω της ηλεκτρονικής πλατφόρμας του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali WebApp</a> έχετε την δυνατότητα προβολής της οικονομικής σας καρτέλας (χρεώσεις μαθημάτων, υπόλοιπο διδάκτρων, όριο ανεξόφλητων διδάκτρων, δόσεις μαθημάτων).
        `;

        return { title, body };
    };

    const SendNotes = () => {
        moment.locale('el');
        let title = `Αποστολή φυλλαδίων`;
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        σας ενημερώνουμε ότι έχει δρομολογηθεί η αποστολή νέου πακέτου σημειώσεων μέσω ACS με tracking number: _TRACKING_NUMBER_
        </p>
        `;

        return { title, body };
    };

    const BankAccount = () => {
        moment.locale('el');
        let title = `Τραπεζικοί Λογαριασμοί - Πανεπιστημιακά Φροντιστήρια ΒΙΤΑΛΗ`;
        let body = `
        Καλησπέρα σας
        <br/>
        <br/>
        Οι τραπεζικοί λογαριασμοί του φροντιστηρίου είναι :
        <br/>
        <br/>
        <b>Τράπεζα ALPHA BANK</b>
        <ul>
            <li>IBAN : GR80 0140 1150 1150 0233 0000 360</li>
            <li>Δικαιούχος :  <b>Χ.ΒΙΤΑΛΗ & ΣΙΑ ΟΕ / ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ</b></li>
        </ul>
        <br/>
        <b>Τράπεζα Πειραιώς - <u><i>Για όσους καταθέτουν μόνο από Τράπεζα Πειραιώς</u></i></b>
        <ul>
            <li>IBAN : GR32 0172 0230 0050 2310 2707 093</li>
            <li>Δικαιούχος :  <b>Χ.ΒΙΤΑΛΗ & ΣΙΑ ΟΕ / ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ</b></li>
        </ul>
        <br/>
        <br/>
        <b>Προσοχή : </b>
         <ul>
            <li>Στον τραπεζικό λογαριασμό της <b>Τράπεζας Πειραιώς</b> μπορείτε να κάνετε κατάθεση <b>μόνο από Τράπεζα Πειραιώς.</b></li>
            <li>Αν χρησιμοποιείτε οποιαδήποτε άλλα τράπεζα θα πρέπει να χρησιμοποιήσετε <b>οπωσδήποτε</b> τον λογαριασμό της <b>ALPHA BANK ώστε να μην επιβαρυνθείτε χρεώσεις εμβασμάτων (οι χρεώσεις εμβασμάτων βαρύνουν τον εντολέα)</b>.</b></li>
        </ul>
        <br/>
        <br/>
        Στο πεδίο: <b>ΔΙΚΑΙΟΛΟΓΙΑ - ΑΙΤΙΟΛΟΓΙΑ ΠΡΟΣ ΔΙΚΑΙΟΥΧΟ - ΠΛΗΡΟΦΟΡΙΕΣ</b> πρέπει να αναγράφετε <b>το ονοματεπώνυμο του φοιτητή.</b>
        <br/>
        <br/>
        Μετά την κατάθεση στείλτε μας αντίγραφο του καταθετηρίου στο email: <span style="color: rgb(0, 0, 230)">info@vitali.gr</span> ώστε να πιστώσουμε άμεσα την καρτέλα σας.
        `;

        return { title, body };
    };

    const PayClassHoursHalf = (classTitle) => {
        moment.locale('el');
        let title = `ΕΝΗΜΕΡΩΣΗ ΔΙΔΑΚΤΡΩΝ - ΤΜΗΜΑ (μισές διδακτικές ώρες)`;
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        Σας ενημερώνουμε ότι θα πρέπει άμεσα να τακτοποιήσετε την <u>αποπληρωμή της δεύτερης δόσης των διδάκτρων</u> για το μάθημα <u><b>"${classTitle}"</u>, καθώς ολοκληρώθηκαν οι μισές διδακτικές ώρες.</b>
        </p>
        <br/>
        ${BankPaymentGateways}
        `;

        return { title, body };
    };

    const PayClassHours = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let classPriceHalfLocalized = convertToEUR(classPrice / 2);
        let title = 'Ενημέρωση εγγραφής - δίδακτρα';
        let body = `
        [αυτοματοποιημένο μήνυμα - λογιστήριο]
        <br/>
        <br/>
        Καλησπέρα σας,
        <br/>
        <br/>
        Καθώς ολοκληρώθηκε το πρώτο μάθημα για το <b>${classTitle}</b> , θα πρέπει να προχωρήσετε στην εγγραφή σας ώστε να μπορέσετε να συνεχίσετε τα μαθήματά σας.
        <br/>
        <br/>
        Τα δίδακτρα για το τμήμα <b>${classTitle}</b> είναι συνολικά <b>${classPriceLocalized}</b> και καταβάλλονται σε <b>2 ισόποσες μηνιαίες δόσεις.</b>
        <br/>
        <ul>
            <li>Η πρώτη δόση <b>${classPriceHalfLocalized}</b> καταβάλλεται μετά το πρώτο μάθημα και πριν την διεξαγωγή του δευτέρου και αποτελεί την εγγραφή σας στο πρόγραμμα.</li>
            <li>Η δεύτερη δόση <b>${classPriceHalfLocalized}</b> καταβάλλεται ένα μήνα μετά την πρώτη δόση. </li>
        </ul>
        <br/>
        Αναλυτικά οι δόσεις των μαθημάτων που παρακολουθείτε αναγράφονται στην διαδικτυακή εφαρμογή <a href='https://webapp.vitali.gr'>Vitali WebApp</a> επιλέγοντας την οικονομική σας καρτέλα. 
        <br/>
        <br/>
        ${BankPaymentGateways}
        <br/>
        <b>Παροχές προγραμμάτων και όροι λειτουργίας</b>
        <br/>
        <br/>
        <b>Μετά την εγγραφή σας στο μάθημα αποκτάτε πλήρη πρόσβαση</b> μέσω της πλατφόρμας <a href='https://webapp.vitali.gr'>Vitali WebApp</a> στο εκπαιδευτικό υλικό και στις βιντεοσκοπήσεις των μαθημάτων (τα οποία αποτελούν πρόσθετες  παροχές προς τους μαθητές μας και παρέχονται <b>ΔΩΡΕΑΝ</b>).
        <br/>
        <br/>
        Όλα τα μαθήματα βιντεοσκοπούνται και μπορείτε να τα παρακολουθήσετε ξανά ως επανάληψη ή αναπλήρωση (σε περίπτωση απουσίας σας). Τα βίντεο των μαθημάτων, παραμένουν προσβάσιμα για όλο το διάστημα που συμμετέχετε στο τμήμα και διαγράφονται μετά το πέρας της εξεταστικής σας περιόδου.
        <br/>
        <br/>
        Στην βιντεοσκόπηση των μαθημάτων τηρούνται <b>όλοι οι κανονες για την προστασία προσωπικών δεδομένων (GDPR),</b> δεν καταγράφονται ονόματα, ούτε η εικόνα που προέρχεται από τις κάμερες των σπουδαστών και του διδάσκοντα παρά μόνο ο ήχος του μαθήματος και βιντεοσκοπείται μόνο κοινή οθόνη χρήσης (shared screen) του διδάσκοντα.
        <br/>
        <br/>
        <b>Τα πνευματικά δικαιώματα του εκπαιδευτικού υλικού αλλά και των βιντεοσκοπήσεων των μαθημάτων ανήκουν αποκλειστικά και μόνο στον Εκπαιδευτικό Όμιλο ΒΙΤΑΛΗ και η παράνομη χρήση τους είναι έκνομη ενέργεια η οποία διώκεται ποινικά. </b>
        <br/>
        <br/>
        <b>Η μη καταβολή οφειλόμενων διδάκτρων θα προκαλέσει τον προσωρινό ή μόνιμο αποκλεισμό σας από τα μαθήματα τα οποία παρακολουθείτε.</b>
        `;

        return { title, body };
    };

    const PayPrivateClassHoursOnePerson = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let priceX4 = convertToEUR(classPrice * 4);
        let title = 'Ενημέρωση εγγραφής - δίδακτρα';
        let body = `
        [αυτοματοποιημένο μήνυμα - λογιστήριο]
        <br/>
        <br/>
        Καλησπέρα σας,
        <br/>
        <br/>
        Καθώς ολοκληρώθηκε το πρώτο μάθημα για το <b>${classTitle}</b>, θα πρέπει να προχωρήσετε στην εγγραφή σας ώστε να μπορέσετε να συνεχίσετε τα μαθήματά σας.
        <br/>
        Το δίδακτρα για το τμήμα σας υπολογίζονται ανά διδακτική ώρα και η χρέωση είναι <b>${classPriceLocalized}</b> την ώρα.
        <br/>
        Τις συνολικές ώρες του προγράμματος τις καθορίζετε σε συνεργασία με την καθηγήτρια ή τον καθηγητή σας.
        <br/>
        <ul>
            <li>Η πρώτη καταβολή διδάκτρων πρέπει να γίνει μόλις ολοκληρωθεί το πρώτο μάθημα και αποτελεί και την εγγραφή σας στο πρόγραμμα.</li>
            <li>Οι επόμενες καταβολές γίνονται μετά την ολοκλήρωση κάθε μαθήματος.</li>
        </ul>
        <br/>
        Αναλυτικά οι χρεώσεις των μαθημάτων που παρακολουθείτε αναγράφονται στην διαδικτυακή εφαρμογή <a href="https://webapp.vitali.gr">Vitali WebApp</a> επιλέγοντας την οικονομική σας καρτέλα. 
        <br/>
        <br/>
        ${BankPaymentGateways}
        <br/>
        <b>Παροχές προγραμμάτων και όροι λειτουργίας</b>
        <br/>
        <br/>
        <b>Μετά την εγγραφή σας στο μάθημα αποκτάτε πλήρη πρόσβαση</b> μέσω της πλατφόρμας <a href='https://webapp.vitali.gr'>Vitali WebApp</a> στο εκπαιδευτικό υλικό και στις βιντεοσκοπήσεις των μαθημάτων (τα οποία αποτελούν πρόσθετες  παροχές προς τους μαθητές μας και παρέχονται <b>ΔΩΡΕΑΝ</b>).
        <br/>
        <br/>
        Όλα τα μαθήματα βιντεοσκοπούνται και μπορείτε να τα παρακολουθήσετε ξανά ως επανάληψη ή αναπλήρωση (σε περίπτωση απουσίας σας). Τα βίντεο των μαθημάτων, παραμένουν προσβάσιμα για όλο το διάστημα που συμμετέχετε στο τμήμα και διαγράφονται μετά το πέρας της εξεταστικής σας περιόδου.
        <br/>
        <br/>
        Στην βιντεοσκόπηση των μαθημάτων τηρούνται <b>όλοι οι κανονες για την προστασία προσωπικών δεδομένων (GDPR),</b> δεν καταγράφονται ονόματα, ούτε η εικόνα που προέρχεται από τις κάμερες των σπουδαστών και του διδάσκοντα παρά μόνο ο ήχος του μαθήματος και βιντεοσκοπείται μόνο κοινή οθόνη χρήσης (shared screen) του διδάσκοντα.
        <br/>
        <br/>
        <b>Τα πνευματικά δικαιώματα του εκπαιδευτικού υλικού αλλά και των βιντεοσκοπήσεων των μαθημάτων ανήκουν αποκλειστικά και μόνο στον Εκπαιδευτικό Όμιλο ΒΙΤΑΛΗ και η παράνομη χρήση τους είναι έκνομη ενέργεια η οποία διώκεται ποινικά. </b>
        <br/>
        <br/>
        <b>Η μη καταβολή οφειλόμενων διδάκτρων θα προκαλέσει τον προσωρινό ή μόνιμο αποκλεισμό σας από τα μαθήματα τα οποία παρακολουθείτε.</b>
        <br/>
        <br/>
        <b>Ακύρωση μαθήματος συμβαίνει μόνο για λόγους ανωτέρας βίας και κατόπιν έγκαιρης ενημέρωσης. Συχνή ακύρωση μαθημάτων οδηγεί στην συνολική ακύρωση του προγράμματος.</b>
        `;

        return { title, body };
    };

    const PayPrivateClassHoursTwoPersons = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let priceX4 = convertToEUR(classPrice * 4);
        let title = 'Ενημέρωση εγγραφής - δίδακτρα';
        let body = `
        [αυτοματοποιημένο μήνυμα - λογιστήριο]
        <br/>
        <br/>
        Καλησπέρα σας,
        <br/>
        <br/>
        Καθώς ολοκληρώθηκε το πρώτο μάθημα για το <b>${classTitle}</b>, θα πρέπει να προχωρήσετε στην εγγραφή σας ώστε να μπορέσετε να συνεχίσετε τα μαθήματά σας.
        <br/>
        <br/>
        Το δίδακτρα για το τμήμα σας υπολογίζονται ανά διδακτική ώρα και η χρέωση είναι <b>${classPriceLocalized}</b> την ώρα. 
        <br/>
        <br/>
        <b>Τα δίδακτρα έχουν υπολογιστεί με βάσει τον αριθμό των σπουδαστών που έχουν δηλώσει ενδιαφέρον</b>. Αν αυτό μεταβληθεί η χρέωση ανά διδακτική ώρα θα τροποποιηθεί. 
        <br/>
        <br/>
        Σε περίπτωση απουσίας, σας ενημερώνουμε ότι ισχύει κανονικά η χρέωση εφόσον το μάθημα πραγματοποιηθεί κανονικά.
        <br/>
        <br/>
        Τις συνολικές ώρες του προγράμματος τις καθορίζετε σε συνεργασία με την καθηγήτρια ή τον καθηγητή σας. 
        <br/>
        <br/>
        <ul>
            <li>Η πρώτη καταβολή διδάκτρων πρέπει να γίνει μόλις ολοκληρωθεί το πρώτο μάθημα και αποτελεί και την εγγραφή σας στο πρόγραμμα.</li>
            <li>Οι επόμενες καταβολές γίνονται μετά την ολοκλήρωση κάθε μαθήματος. </li>
        </ul>
        <br/>
        Αναλυτικά οι χρεώσεις των μαθημάτων που παρακολουθείτε αναγράφονται στην διαδικτυακή εφαρμογή <a href='https://webapp.vitali.gr'>Vitali WebApp</a> επιλέγοντας την οικονομική σας καρτέλα. 
        <br/>
        ${BankPaymentGateways}
        <br/>
        <b>Παροχές προγραμμάτων και όροι λειτουργίας</b>
        <br/>
        <br/>
        <b>Μετά την εγγραφή σας στο μάθημα αποκτάτε πλήρη πρόσβαση</b> μέσω της πλατφόρμας <a href='https://webapp.vitali.gr'>Vitali WebApp</a> στο εκπαιδευτικό υλικό και στις βιντεοσκοπήσεις των μαθημάτων (τα οποία αποτελούν πρόσθετες  παροχές προς τους μαθητές μας και παρέχονται <b>ΔΩΡΕΑΝ</b>).
        <br/>
        <br/>
        Όλα τα μαθήματα βιντεοσκοπούνται και μπορείτε να τα παρακολουθήσετε ξανά ως επανάληψη ή αναπλήρωση (σε περίπτωση απουσίας σας). Τα βίντεο των μαθημάτων, παραμένουν προσβάσιμα για όλο το διάστημα που συμμετέχετε στο τμήμα και διαγράφονται μετά το πέρας της εξεταστικής σας περιόδου.
        <br/>
        <br/>
        Στην βιντεοσκόπηση των μαθημάτων τηρούνται <b>όλοι οι κανονες για την προστασία προσωπικών δεδομένων (GDPR),</b> δεν καταγράφονται ονόματα, ούτε η εικόνα που προέρχεται από τις κάμερες των σπουδαστών και του διδάσκοντα παρά μόνο ο ήχος του μαθήματος και βιντεοσκοπείται μόνο κοινή οθόνη χρήσης (shared screen) του διδάσκοντα.
        <br/>
        <br/>
        <b>Τα πνευματικά δικαιώματα του εκπαιδευτικού υλικού αλλά και των βιντεοσκοπήσεων των μαθημάτων ανήκουν αποκλειστικά και μόνο στον Εκπαιδευτικό Όμιλο ΒΙΤΑΛΗ και η παράνομη χρήση τους είναι έκνομη ενέργεια η οποία διώκεται ποινικά. </b>
        <br/>
        <br/>
        <b>Η μη καταβολή οφειλόμενων διδάκτρων θα προκαλέσει τον προσωρινό ή μόνιμο αποκλεισμό σας από τα μαθήματα τα οποία παρακολουθείτε.</b>
        <br/>
        <br/>
        <b>Ακύρωση μαθήματος συμβαίνει μόνο για λόγους ανωτέρας βίας και κατόπιν έγκαιρης ενημέρωσης. Συχνή ακύρωση μαθημάτων οδηγεί στην συνολική ακύρωση του προγράμματος.</b>
        `;

        return { title, body };
    };

    const PayAsep = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let priceX4 = convertToEUR(classPrice * 4);
        let title = 'Ενημέρωση εγγραφής - δίδακτρα ΑΣΕΠ 2025';
        let body = `
        [αυτοματοποιημένο μήνυμα - λογιστήριο]
        <br/>
        <br/>
        Καλησπέρα σας,
        <br/>
        <br/>
        Καθώς ολοκληρώθηκε το πρώτο μάθημα για τον <b>2ο πανελλήνιο γραπτό διαγωνισμό (ΑΣΕΠ 2025)</b> θα πρέπει να προχωρήσετε στην εγγραφή σας ώστε να μπορέσετε να συνεχίσετε τα μαθήματά σας.
        <br/>
        Τα δίδακτρα για την εγγραφή σας σε όλο το πρόγραμμα προετοιμασίας ανέρχονται σε <b>960€</b>. 
        <br/>
        (Περισσότερες πληροφορίες μπορείτε να βρείτε στην <a href="https://vitali.gr/">ιστοσελίδα μας</a>).
        <br/>
        <br/>
        Τα δίδακτρα καταβάλλονται :
        <br/>
        <br/>
        <ul>
            <li>Σε <b>3 ισόποσες μηνιαίες δόσεις (320€)</b> με καταβολή της πρώτης δόσης άμεσα (αποτελεί την εγγραφή σας στο πρόγραμμα).</li>
            <li>Σε <b>12 μηνιαίες άτοκες δόσεις με πιστωτική κάρτα.</b></li>
            <li><b>Με εφάπαξ εξόφληση των διδάκτρων άμεσα γίνεται έκπτωση 60€ (δίδακτρα 900€).</b></li>
        </ul>
        <br/>
        Αναλυτικά οι χρεώσεις των μαθημάτων που παρακολουθείτε αναγράφονται στην διαδικτυακή εφαρμογή <a href='https://webapp.vitali.gr'>Vitali WebApp</a> επιλέγοντας την οικονομική σας καρτέλα. 
        <br/>
        <br/>
        <i>Εάν επιλέξετε να εγγραφείτε σε κάποιες από τις ενότητες και όχι σε ολόκληρο το πρόγραμμα τα δίδακτρα υπολογίζονται ανά ενότητα και θα πρέπει να επικοινωνήσετε μαζί μας.</i>
        <br/>
        <br/>
        ${BankPaymentGateways}
        <br/>
        <b>Παροχές προγραμμάτων και όροι λειτουργίας</b>
        <br/>
        <br/>
        <b>Μετά την εγγραφή σας στο μάθημα αποκτάτε πλήρη πρόσβαση</b> μέσω της πλατφόρμας <a href='https://webapp.vitali.gr'>Vitali WebApp</a> στο εκπαιδευτικό υλικό και στις βιντεοσκοπήσεις των μαθημάτων (τα οποία αποτελούν πρόσθετες  παροχές προς τους μαθητές μας και παρέχονται <b>ΔΩΡΕΑΝ</b>).
        <br/>
        <br/>
        Όλα τα μαθήματα βιντεοσκοπούνται και μπορείτε να τα παρακολουθήσετε ξανά ως επανάληψη ή αναπλήρωση (σε περίπτωση απουσίας σας). Τα βίντεο των μαθημάτων, παραμένουν προσβάσιμα για όλο το διάστημα που συμμετέχετε στο τμήμα και διαγράφονται μετά το πέρας της εξεταστικής σας περιόδου.
        <br/>
        <br/>
        Στην βιντεοσκόπηση των μαθημάτων τηρούνται <b>όλοι οι κανονες για την προστασία προσωπικών δεδομένων (GDPR),</b> δεν καταγράφονται ονόματα, ούτε η εικόνα που προέρχεται από τις κάμερες των σπουδαστών και του διδάσκοντα παρά μόνο ο ήχος του μαθήματος και βιντεοσκοπείται μόνο κοινή οθόνη χρήσης (shared screen) του διδάσκοντα.
        <br/>
        <br/>
        <b>Τα πνευματικά δικαιώματα του εκπαιδευτικού υλικού αλλά και των βιντεοσκοπήσεων των μαθημάτων ανήκουν αποκλειστικά και μόνο στον Εκπαιδευτικό Όμιλο ΒΙΤΑΛΗ και η παράνομη χρήση τους είναι έκνομη ενέργεια η οποία διώκεται ποινικά. </b>
        <br/>
        <br/>
        <b>Η μη καταβολή οφειλόμενων διδάκτρων θα προκαλέσει τον προσωρινό ή μόνιμο αποκλεισμό σας από τα μαθήματα τα οποία παρακολουθείτε.</b>
        `;

        return { title, body };
    };

    const PayClassPostGraduate = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let priceX4 = convertToEUR(classPrice * 4);
        let title = 'ΕΝΗΜΕΡΩΣΗ ΔΙΔΑΚΤΡΩΝ - ΙΔΙΑΙΤΕΡΟ (3 ΑΤΟΜΩΝ)';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        το μάθημα ${classTitle} είναι ιδιαίτερο (Μεταπτυχιακού Προγράμματος) και η χρέωση είναι ${classPriceLocalized} ανά διδακτική ώρα.
        <br/>
        <ul>
            <li><b><u>Η πρώτη καταβολή των διδάκτρων πρέπει να πραγματοποιηθεί μόλις ολοκληρωθεί το πρώτο σας μάθημα</u></b></li>
            <li><b>Κάθε επόμενη καταβολή πρέπει να γίνεται ανά 4 διδακτικές ώρες (4ώρες * ${classPriceLocalized} = ${priceX4}). </b></li>
        </ul>
        </p>
        <br/>
        Αν δεν πραγματοποιηθεί καταβολή διδάκτρων σε αυτό το διάστημα, το σύστημα αυτοματοποιημένα μας "απαγορεύει" να ορίσουμε επόμενο μάθημα.
        <br/>
        <br/>
        ${BankPaymentGateways}
        `;

        return { title, body };
    };

    const PrepareEsdda = (classTitle, classPrice) => {
        moment.locale('el');
        let title = 'Ενημέρωση εγγραφής - δίδακτρα  1ο Στάδιο ΕΣΔΔΑ';
        let body = `
        [αυτοματοποιημένο μήνυμα - λογιστήριο]
        <br/>
        <br/>
        Καλησπέρα σας,
        <br/>
        <br/>
        Καθώς ολοκληρώθηκε το πρώτο μάθημα για τον εισαγωγικό διαγωνισμό της <b>Εθνικής Σχολής Δημόσιας Διοίκησης & Αυτοδιοίκησης</b> θα πρέπει να προχωρήσετε στην εγγραφή σας ώστε να μπορέσετε να συνεχίσετε τα μαθήματά σας.
        <br/>
        <br/>
        Τα δίδακτρα για την εγγραφή σας στο πρόγραμμα προετοιμασίας για το Α Στάδιο του  εισαγωγικού διαγωνισμού για την ΕΣΔΔΑ ανέρχονται σε 1500€. (Περισσότερες πληροφορίες μπορείτε να βρείτε στην <a href="https://vitali.gr/">ιστοσελίδα μας</a>).
        <br/>
        <br/>
        <ul>
            <li><b>σε 5 ισόποσες μηνιαίες δόσεις των ${convertToEUR(300)}</b></li>
            <li><b>ή εφάπαξ</b> καταβάλλοντας το ποσό των <b>${convertToEUR(1425)} (έκπτωση 5%)</b></li>
        </ul>
        <br/>
        Τα δίδακτρα καταβάλλονται :
        <br/>
        <ul>
            <li>Σε <b>5 ισόποσες μηνιαίες δόσεις (300€)</b> με καταβολή της πρώτης δόσης <b>άμεσα</b> (αποτελεί την εγγραφή σας στο πρόγραμμα).</li>
            <li>Σε <b>12 μηνιαίες άτοκες δόσεις</b> με πιστωτική κάρτα.</li>
            <li><b>Με εφάπαξ εξόφληση των διδάκτρων άμεσα γίνεται έκπτωση 5% (1425€).</b></li>
        </ul>
        <br/>
        Αναλυτικά οι δόσεις των μαθημάτων που παρακολουθείτε αναγράφονται στην διαδικτυακή εφαρμογή <a href='https://webapp.vitali.gr'>Vitali WebApp</a> επιλέγοντας την οικονομική σας καρτέλα. 
        <br/>
        <br/>
        <i>Εάν επιλέξετε να εγγραφείτε σε κάποιες από τις ενότητες και όχι σε ολόκληρο το πρόγραμμα τα δίδακτρα υπολογίζονται ανά ενότητα και θα πρέπει να επικοινωνήσετε μαζί μας.</i>
        <br/>
        <br/>
        ${PaymentGatewayMode2}
        <br/>
        <b>Παροχές προγραμμάτων και όροι λειτουργίας</b>
        <br/>
        <br/>
        <b>Μετά την εγγραφή σας στο μάθημα αποκτάτε πλήρη πρόσβαση</b> μέσω της πλατφόρμας <a href='https://webapp.vitali.gr'>Vitali WebApp</a> στο εκπαιδευτικό υλικό και στις βιντεοσκοπήσεις των μαθημάτων (τα οποία αποτελούν πρόσθετες  παροχές προς τους μαθητές μας και παρέχονται <b>ΔΩΡΕΑΝ</b>).
        <br/>
        <br/>
        Όλα τα μαθήματα βιντεοσκοπούνται και μπορείτε να τα παρακολουθήσετε ξανά ως επανάληψη ή αναπλήρωση (σε περίπτωση απουσίας σας). Τα βίντεο των μαθημάτων, παραμένουν προσβάσιμα για όλο το διάστημα που συμμετέχετε στο τμήμα και διαγράφονται μετά το πέρας της εξεταστικής σας περιόδου.
        <br/>
        <br/>
        Στην βιντεοσκόπηση των μαθημάτων τηρούνται <b>όλοι οι κανονες για την προστασία προσωπικών δεδομένων (GDPR),</b> δεν καταγράφονται ονόματα, ούτε η εικόνα που προέρχεται από τις κάμερες των σπουδαστών και του διδάσκοντα παρά μόνο ο ήχος του μαθήματος και βιντεοσκοπείται μόνο κοινή οθόνη χρήσης (shared screen) του διδάσκοντα.
        <br/>
        <br/>
        <b>Τα πνευματικά δικαιώματα του εκπαιδευτικού υλικού αλλά και των βιντεοσκοπήσεων των μαθημάτων ανήκουν αποκλειστικά και μόνο στον Εκπαιδευτικό Όμιλο ΒΙΤΑΛΗ και η παράνομη χρήση τους είναι έκνομη ενέργεια η οποία διώκεται ποινικά. </b>
        <br/>
        <br/>
        <b>Η μη καταβολή οφειλόμενων διδάκτρων θα προκαλέσει τον προσωρινό ή μόνιμο αποκλεισμό σας από τα μαθήματα τα οποία παρακολουθείτε.</b>
        `;

        return { title, body };
    };

    const NotesEsdda = (classTitle, classPrice) => {
        moment.locale('el');
        let title = 'ΑΡΧΕΙΑ/ΣΗΜΕΙΩΣΕΙΣ ΜΑΘΗΜΑΤΩΝ ΕΣΔΔΑ';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        σας ενημερώνουμε ότι <u>με την εγγραφή σας</u> στο πρόγραμμα προετοιμασίας για τον διαγωνισμό της ΕΣΔΔΑ <b>έχετε άμεση πρόσβαση στα ΑΡΧΕΙΑ & στα ΒΙΝΤΕΟ των μαθημάτων μέσω της πλατφόρμας</b> <a href = "https://vitali.gr/vitali-webapp/">https://vitali.gr/vitali-webapp</a>
        <br/>
        <ul>
            <li>Οι σημειώσεις είναι διαθέσιμες μόνο σε ηλεκτρονική μορφή και <i>όχι σε έντυπη</i>. </li>
            <li>Η πρόσβαση γίνεται από το menu "ΑΡΧΕΙΑ" κάθε τμήματος και έχετε την δυνατότητα αποθήκευσης ή απλά ανάγνωσής τους.</li>
            <li>Κάθε μήνα θα αναρτώνται νέα φυλλάδια ανάλογα με την πρόοδο  των μαθημάτων, πάντα κατόπιν σχετικής ενημέρωσης και αφού πρώτα έχετε καταβάλλει την μηνιαία δόση των διδάκτρων.</li>
        </ul>
        </p>
        <br/>
        <p>
        Συγκεκριμένα αυτή τη στιγμή υπάρχουν διαθέσιμα στην πλατφόρμα:
        <ul>
            <li><b><u>το ΦΥΛΛΑΔΙΟ 1</u></b> του μαθήματος <b>Οργάνωση και Λειτουργία του Κράτους</b></li>
            <li><b><u>το ΦΥΛΛΑΔΙΟ 1</u></b> του μαθήματος <b>Μικροοικονομικής – Μακροοικονομικής και Δημόσιας Οικονομικής</b></li>
        </ul>
        </p>
        <br/>
        <br/>
        Παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση
        `;

        return { title, body };
    };

    /*
        Περιγραφή:

        Το μήνυμα πρέπει να φεύγει αυτόματα για τον καθηγητή μέσα από τον διάλογο των αναθέσεων 
        (δεν χρειάζεται να φεύγει χειροκίνητα). Αν σε μια ανάθεση προσθέσουμε και νέο καθηγητή να
        φεύγει μετά το κουμπί “Επόμενο” και στον νέο καθηγητή (ή να ψάχνει σε ποιους καθηγητές δεν 
        έχει σταλεί το συγκεκριμένο μήνυμα για αυτήν την ανάθεση και να φεύγει).
    */
    const JobPostings = (jobPostingID, subCategory1, subCategory2, courseTitle) => {
        moment.locale('el');
        let title = `Νέα ανάθεση ${jobPostingID}`;
        let body = `
        Καλησπέρα σας,
        <br />
        σας ενημερώνουμε ότι σας έχουμε στείλει προς έλεγχο ένα νέο μάθημα <b>${subCategory1} / ${subCategory2} / ${courseTitle}</b>.
        <br />
        <br />
        Μπορείτε να το ελέγξετε μέσω της εφαρμογής <a href="https://webapp.vitali.gr">Vitali WebApp</a>.
        <br />
        Το προς ανάθεση μάθημα θα το βρείτε κάτω από την επιλογή <b>Αναθέσεις</b>.
        <br />
        <br />
        Αν μέχρι στιγμής δεν έχουν ανέβει σημειώσεις στο σύστημα για να μην χάνουμε χρόνο μπορείτε να δείτε την περιγραφή του μαθήματος στην αντίστοιχη σελίδα του Πανεπιστημίου.
        <br />
        Στην συνέχεια ο φοιτητής θα έχει χρόνο να μας στείλει τις απαραίτητες σημειώσεις. 
        <br />
        <br />
        <b>Στην απάντηση σας καλό είναι να συμπληρώσετε όλα τα απαραίτητα πεδία.</b>
        <br />
        <br />
        Παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση.
        `;

        return { title, body };
    };

    const ZoomLink = (zoomMeetingLink) => {
        moment.locale('el');
        let title = 'Zoom Link';
        let body = `
        Καλησπέρα σας,
        <p>
        σας ενημερώνουμε ότι το zoom link για το μάθημά σας είναι : ${zoomMeetingLink}
        </p>
        <p>
        Παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση
        </p>
        `;

        return { title, body };
    };

    /*
    Το μήνυμα δημιουργείται αυτόματα όταν κάποια από τις αιτήσεις ενός μαθητή μπαίνει σε φραγή. Τότε μπαίνουν σε φραγή όλες οι αιτήσεις του μαθητή και φεύγει το εμαιλ. 
    Η γραμματεία δεν ελέγχει το μήνυμα αλλά μόνο την αλλαγή status της αίτησης.
    */
    const OnHoldReminder = () => {
        moment.locale('el');
        const title = `Ενημερωτικό μήνυμα φραγής καρτέλας σπουδαστή`;
        let body = '';
        body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλημέρα σας.
                <br />
                <br />
                Σε συνέχεια προηγούμενης γραπτής μας ενημέρωσης, σας γνωστοποιούμε πως ο λογαριασμός σας στην διαδικτυακή πλατφόρμα Vitali WebApp, έχει τεθεί <b>προσωρινά</b> σε κατάσταση <b>φραγής</b>, λόγω ανεξόφλητων διδάκτρων.
                <br />
                <br />
                Στην κατάσταση φραγής δεν μπορείτε να συνδεθείτε σε επόμενα μαθήματα και δεν έχετε πρόσβαση στα αρχεία των μαθημάτων (σημειώσεις), ούτε και στα βιντεοσκοπημένα μαθήματα.
                <br />
                <br />
                Προκειμένου να αποκτήσετε ξανά πλήρη πρόσβαση στον λογαριασμό και στα μαθήματα σας, παρακαλείσθε όπως τακτοποιήσετε άμεσα την οικονομική σας εκκρεμότητα.
                `;

        return { title, body };
    };

    /*
        Το μήνυμα πρέπει να δημιουργείται μόνο αυτόματα από το κουμπί του διαλόγου στο παρουσιολόγιο. 
        Μόλις πατήσουμε το κουμπί ανοίγει όπως τώρα το παράθυρο με το μήνυμα για τον καθηγητή με προκαθορισμένο κείμενο 
        το παρακάτω (νέο κείμενο). Το μήνυμα βέβαια η γραμματεία μπορεί να το αλλάξει κάνοντας edit. 
        Για τον λόγο αυτό στον τίτλο έχω βάλει κάτι πιο γενικό (ενημερωτικό μήνυμα - τμήμα [τμημα]) ώστε η γραμματεία να μπορεί να γράψει ότι θέλει.
    */
    const NextLessonNotSetReminder = (classTitle) => {
        moment.locale('el');
        const title = `Ενημερωτικό μήνυμα - Τμήμα ${classTitle}`;
        let body = '';
        body = `
                    Καλησπέρα σας.
                    <br />
                    <br />
                    Κατόπιν ελέγχου διαπιστώθηκε ότι για το τμήμα ${classTitle} <b>δεν ορίστηκε επόμενο μάθημα</b>.
                    <br />
                    <br />
                    Παρακαλείσθε όπως ενημερώσετε την γραμματεία του φροντιστηρίου για το πότε έχει οριστεί το επόμενο μάθημα ώστε να προβεί στις απαραίτητες ενέργειες.
                    <br />
                    <br />
                    <i>Υπενθύμιση :</i>
                    <br />
                    Στα παρουσιολόγια υπάρχει ειδική ένδειξη που δείχνει ότι το μάθημα ήταν το <b>προτελευταίο</b> ή το <b>τελευταίο</b> για το συγκεκριμένο τμήμα. Είναι πολύ σημαντική η σωστή ενημέρωση της γραμματείας για την ορθή λειτουργία του φροντιστηρίου.
                    <br />
                    <br />
                    Παραμένουμε στην διάθεση σας για κάθε διευκρίνιση.
                    `;

        return { title, body };
    };

    /*
    Το μήνυμα δημιουργείται από τον αλγόριθμο οικονομικών αυτόματα χωρίς την μεσολάβηση της γραμματείας. 
    */
    const InstallmentReminder = (amount) => {
        moment.locale('el');
        const nowDate = moment().format('DD-MM-YYYY');
        let currencyFormatAmount = amount.toLocaleString('el-GR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const title = `Ενημέρωση καταβολής δόσης διδάκτρων`;
        let body = '';
        body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλημέρα σας.
                <br />
                <br />
                Σας υπενθυμίζουμε πως για τα μαθήματα που παρακολουθείτε, σήμερα <b>${nowDate}</b> εκπνέει η περίοδος εξόφλησης της δόσης των διδάκτρων <b>${currencyFormatAmount}€</b>.
                <br />
                <br />
                Μέσω της ηλεκτρονικής πλατφόρμας του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali WebApp</a> έχετε την δυνατότητα προβολής της οικονομικής σας καρτέλας (χρεώσεις μαθημάτων, υπόλοιπο διδάκτρων, όριο ανεξόφλητων διδάκτρων, δόσεις μαθημάτων).
                <br />
                <br />
                Παρακαλούμε για την άμεση τακτοποίηση των διδάκτρων σας.
                `;

        return { title, body };
    };

    /*
    Το μήνυμα δημιουργείται από τον αλγόριθμο οικονομικών αυτόματα χωρίς την μεσολάβηση της γραμματείας. 
    */
    const RedFlagReminder = () => {
        moment.locale('el');
        const title = `Ενημέρωση πιθανής φραγής καρτέλας σπουδαστή`;
        const body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλημέρα σας.
                <br />
                <br />
                Σας ενημερώνουμε πως ο λογαριασμός σας στην εφαρμογή του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali WebApp</a>, πρόκειται να μπει <b>προσωρινά</b> σε <b>κατάσταση φραγής</b>, λόγω ανεξόφλητων διδάκτρων.
                <br />
                <br />
                Στην κατάσταση φραγής δεν θα μπορείτε να συνδεθείτε σε επόμενα μαθήματα και δεν θα έχετε πρόσβαση στα αρχεία των μαθημάτων, ούτε και στα βιντεοσκοπημένα μαθήματα.
                <br />
                <br />
                Προκειμένου να παραμείνει ο λογαριασμός σας ενεργός, παρακαλείστε να τακτοποιήσετε άμεσα την οικονομική σας εκκρεμότητα.
                <br />
                <br />
                Σας θυμίζουμε ότι μέσω της ηλεκτρονικής πλατφόρμας του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali WebApp</a>, έχετε την δυνατότητα προβολής της οικονομικής σας καρτέλας (χρεώσεις μαθημάτων, υπόλοιπο διδάκτρων, όριο ανεξόφλητων διδάκτρων, δόσεις μαθημάτων).
                `;

        return { title, body };
    };

    /*
    Πότε δημιουργείται. 
    Το μήνυμα δημιουργείται από τον αλγόριθμο οικονομικών αυτόματα χωρίς την μεσολάβηση της γραμματείας. 
    */
    const AmountDuesReminder = (amount, limit) => {
        moment.locale('el');
        let currencyFormatAmount = amount.toLocaleString('el-GR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const title = `Ενημέρωση καταβολής διδάκτρων`;
        const body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλημέρα σας.
                <br />
                <br />
                Σας ενημερώνουμε πως για τα μαθήματα που παρακολουθείτε με χρέωση ανά διδακτική ώρα, το ανεξόφλητο ποσό των διδάκτρων σας ανέρχεται στα <b>${currencyFormatAmount}€</b>.
                <br />
                <br />
                Μέσω της ηλεκτρονικής πλατφόρμας του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali WebApp</a> έχετε την δυνατότητα προβολής της οικονομικής σας καρτέλας (χρεώσεις μαθημάτων, υπόλοιπο διδάκτρων, όριο ανεξόφλητων διδάκτρων, δόσεις μαθημάτων).
                <br />
                <br />
                Παρακαλούμε για την άμεση τακτοποίηση των διδάκτρων σας.
                `;

        return { title, body };
    };

    const UnreadChatMessages = (unreadMessagesForUser) => {
        const title = `Έχετε αδιάβαστα μηνύματα στο chat!`;
        const body = `
                Έχετε ${unreadMessagesForUser} αδιάβαστα μηνύματα στο support chat, παρακαλώ ελέγξτε τα.
                <br />
                <br />
                Μπορείτε να δείτε τα αδιάβαστα μηνύματα εδώ: <a href='https://webapp.vitali.gr/chat'>https://webapp.vitali.gr/chat</a>
                `;
        return { title, body };
    };

    const TeacherDeletedFile = (username, courseTitle, fileName, classTitle) => {
        const title = `Έγινε προσπάθεια διαγραφής αρχείου από καθηγητή`;
        const body = `
                Καθηγητής: ${username}
                <br/>
                Μάθημα: ${courseTitle}
                <br/>
                Αρχείο: ${fileName}
                <br/>
                Τμήμα: ${classTitle}`;
        return { title, body };
    };

    const RequestPasswordReset = (email, link) => {
        const title = `Αλλαγή κωδικού πρόσβασης`;
        const body = `
                <p style="margin: 0px">Γειά σας,</p>
                <br />
                <p style="margin: 0px">Ακολουθήστε το παρακάτω link για να αλλάξετε τον κωδικό σας για τον λογαριασμό <b>${email}</b>.</p>
                <br />
                <p style="margin: 0px"><a href='${link}'>${link}</a></p>
                <br />
                <p style="margin: 0px">Σε περίπτωση που δεν ζητήσατε αλλαγή κωδικού μπορείτε απλά να αγνοήσετε αυτό το email.</p>
                `;
        return { title, body };
    };

    const PasswordResetSuccess = (email) => {
        const title = `Επιτυχής Αλλαγή Password`;
        const body = `
                <p style="margin: 0px">O κωδικός για τον λογαριασμό <b>${email}</b> άλλαξε με επιτυχία!</p>
                <br />
                `;
        return { title, body };
    };

    const FinalAmountDuesReminder = (remainingAmount) => {
        const title = `Τελική ενημέρωση διδάκτρων`;
        const body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλησπέρα σας.
                <br />
                <br />
                Σας ενημερώνουμε πως για τα μαθήματα που έχετε παρακολουθήσει εμφανίζεται υπόλοιπο διδάκτρων <b>${remainingAmount}</b>.
                <br />
                <br />
                Επειδή τα μαθήματα σας <b>έχουν ήδη ολοκληρωθεί ή πρόκειται να ολοκληρωθούν πολύ σύντομα</b> θα πρέπει να προβείτε σε τακτοποίηση των διδάκτρων σας.
                <br />
                <br />
                Μέσω της ηλεκτρονικής πλατφόρμας του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali WebApp</a> έχετε την δυνατότητα προβολής της οικονομικής σας καρτέλας (χρεώσεις μαθημάτων, υπόλοιπο διδάκτρων, όριο ανεξόφλητων διδάκτρων, δόσεις μαθημάτων).
                `;
        return { title, body };
    };

    const LessonCanceledNotify = (classID, classTitle, startAt) => {
        const formattedDate = moment(startAt).format('DD MMMM YYYY');
        console.log('Formatted date:', formattedDate);

        const title = `Αναβολή μαθήματος: ${classID}`;
        const body = `
                Καλησπέρα σας.
                <br />
                <br />
                Σε ενημερώνουμε ότι το προγραμματισμένο μάθημα για το τμήμα ${classTitle} το οποίο είχε οριστεί την <b>${formattedDate}</b> και ώρα <b>${moment(startAt).format(
            'HH:mm'
        )}</b> αναβάλλεται. 
                <br />
                <br />
                Θα ενημερωθείτε το συντομότερο δυνατόν με νέο μήνυμα για το πότε θα οριστεί το επόμενο μάθημα.
                `;
        return { title, body };
    };

    const AvailableTemplates = [
        { id: 3, value: WelcomeInvitation, label: 'Ενεργοποίηση λογαριασμού στην διαδικτυακή εφαρμογή Vitali WebApp', audience: ['student', 'teacher'] },
        { id: 4, value: EconomicsNotification, label: 'Ενημέρωση Οικονομικών', audience: ['student'] },
        { id: 5, value: StudentsCompletedLessons, label: 'Ενημέρωση διδάκτρων - Μαθητές που έχουν ολοκληρώσει', audience: ['student'] },
        { id: 6, value: SendNotes, label: 'Αποστολή φυλλαδίων' },
        { id: 7, value: BankAccount, label: 'Τραπεζικοί λογαριασμοί', audience: ['student'] },
        { id: 8, value: PayClassHoursHalf, label: 'Ενημέρωση Διδάκτρων - Τμήμα (μισές διδακτικές ώρες)' },
        { id: 9, value: PayClassHours, label: 'Εγγραφή - Δίδακτρα - Τμήμα Ακαδημαϊκό', audience: ['class'] },
        { id: 10, value: PayPrivateClassHoursOnePerson, label: 'Εγγραφή - Δίδακτρα - Χρέωση με την ώρα - 1 άτομο', audience: ['class'] },
        { id: 11, value: PayPrivateClassHoursTwoPersons, label: 'Εγγραφή - Δίδακτρα - Χρέωση με την ώρα - 2 και περισσότερα άτομα', audience: ['class'] },
        { id: 12, value: PayAsep, label: 'Εγγραφή - Δίδακτρα - Πανελλήνιος γραπτός διαγωνισμός ΑΣΕΠ 2025', audience: ['class'] },
        { id: 13, value: PayClassPostGraduate, label: 'Ενημέρωση Διδάκτρων - Μεταπτυχιακό' },
        { id: 14, value: PrepareEsdda, label: 'Εγγραφή - Δίδακτρα - 1ο Σταδιο ΕΣΔΔΑ', audience: ['class'] },
        { id: 15, value: NotesEsdda, label: 'Αρχεία/Σημειώσεις μαθημάτων ΕΣΔΔΑ' },
        { id: 16, value: ZoomLink, label: 'Zoom Link' },
        { id: 17, value: OnHoldReminder, label: 'Ενημερωτικό μήνυμα φραγής καρτέλας σπουδαστή' },
        { id: 18, value: NextLessonNotSetReminder, label: 'Μη ορισμένο επόμενο μάθημα' },
        { id: 19, value: FinalAmountDuesReminder, label: 'Τελική ενημέρωση διδάκτρων', audience: ['student'] },
        { id: 20, value: LessonCanceledNotify, label: 'Αναβολή μαθήματος' },
        { id: 21, value: null, label: 'Άλλο' },
    ];

    return {
        WelcomeInvitation,
        EconomicsNotification,
        StudentsCompletedLessons,
        SendNotes,
        BankAccount,
        PayClassHoursHalf,
        PayClassHours,
        PayPrivateClassHoursOnePerson,
        PayPrivateClassHoursTwoPersons,
        PayAsep,
        PayClassPostGraduate,
        PrepareEsdda,
        NotesEsdda,
        JobPostings,
        ZoomLink,
        OnHoldReminder,
        NextLessonNotSetReminder,
        AmountDuesReminder,
        InstallmentReminder,
        RedFlagReminder,
        UnreadChatMessages,
        TeacherDeletedFile,
        RequestPasswordReset,
        PasswordResetSuccess,
        FinalAmountDuesReminder,
        LessonCanceledNotify,
        AvailableTemplates,
    };
};
