import { readonly, ref, warn } from 'vue';
import axios from 'axios';
import { nanoid } from 'nanoid';
import useStorage from '@/composables/useStorage';
import router from '@/router';
import Utils from '@/utils/Utils';

axios.defaults.withCredentials = true;

const { SetStorage, GetStorage, StorageKeys } = useStorage();
export default () => {
    const Class = Object.freeze({
        CountClasses: 'Class.CountClasses',
        Fetch: 'Class.Fetch',
        Add: 'Class.Add',
        Update: 'Class.Update',
        GetClassInfoById: 'Class.GetClassInfoById',
        FetchStudents: 'Class.FetchStudents',
        DeleteData: 'Class.DeleteData',
    });
    const Request = Object.freeze({
        CountRequests: 'Request.CountRequests',
        ExpiredRequests: 'Request.ExpiredRequests',
        RequestsStats: 'Request.RequestsStats',
        FetchRequests: 'Request.FetchRequests',
        GetRequestInfoById: 'Request.GetRequestInfoById',
        Add: 'Request.Add',
        Update: 'Request.Update',
        FetchPendingRequestsForCourse: 'Request.FetchPendingRequestsForCourse',
        FetchUserClassRequest: 'Request.FetchUserClassRequest',
        FetchRequestsWithOffers: 'Request.FetchRequestsWithOffers',
        FetchLogsForRequest: 'Request.FetchLogsForRequest',
        Delete: 'Request.Delete',
    });

    const User = Object.freeze({
        GetUserInfoById: 'User.GetUserInfoById',
        GetUsersCount: 'User.GetUsersCount',
        FetchUsersByRole: 'User.FetchUsersByRole',
        FetchStudentsAndDues: 'User.FetchStudentsAndDues',
        Add: 'User.Add',
        Update: 'User.Update',
        FetchClasses: 'User.FetchClasses',
        FetchRequests: 'User.FetchRequests',
        FetchDuesForStudent: 'User.FetchDuesForStudent',
        FetchStudentsByName: 'User.FetchStudentsByName',
        FetchTeachersByName: 'User.FetchTeachersByName',
        FetchAccountables: 'User.FetchAccountables',
        FetchGeneralDues: 'User.FetchGeneralDues',
        UpdateGeneralDue: 'User.UpdateGeneralDue',
        AddGeneralDue: 'User.AddGeneralDue',
        DeleteGeneralDue: 'User.DeleteGeneralDue',
        FetchClassesDues: 'User.FetchClassesDues',
        FetchPayments: 'User.FetchPayments',
        DeletePayment: 'User.DeletePayment',
        FetchTeacherClasses: 'User.FetchTeacherClasses',
        FetchTeachers: 'User.FetchTeachers',
        FetchTeachersWithParams: 'User.FetchTeachersWithParams',
        FetchOfficeAdminAndTeachers: 'User.FetchOfficeAdminAndTeachers',
        FetchOfficeAndAdmin: 'User.FetchOfficeAndAdmin',
        FetchUsersByProfileCategory: 'User.FetchUsersByProfileCategory',
        FcmTokenUpdate: 'User.FcmTokenUpdate',
        UpdateMyProfile: 'User.UpdateMyProfile',
        FetchOffice: 'User.FetchOffice',
        FetchUsersByRequests: 'User.FetchUsersByRequests',
        AllowRecordings: 'User.AllowRecordings',
        FetchShortInfo: 'User.FetchShortInfo',
        GetElorusContact: 'User.GetElorusContact',
        CreateTempPassword: 'User.CreateTempPassword',
        SendDeleteFileNotification: 'User.SendDeleteFileNotification',
        FetchUsersByName: 'User.FetchUsersByName',
        WatchedRecordedVideo: 'User.WatchedRecordedVideo',
    });

    const Payments = Object.freeze({
        GetMonthlyPayments: 'Payments.GetMonthlyPayments',
        FetchPaymentCodes: 'Payments.FetchPaymentCodes',
        AddPayment: 'Payments.AddPayment',
        UpdatePayment: 'Payments.UpdatePayment',
        FetchPaymentsByFilter: 'Payments.FetchPaymentsByFilter',
    });

    const Lessons = Object.freeze({
        DayLessons: 'Lessons.DayLessons',
        FetchLessonsForClass: 'Lessons.FetchLessonsForClass',
        FetchLessonInfoById: 'Lessons.FetchLessonInfoById',
        AddLessonForClass: 'Lessons.AddLessonForClass',
        UpdateLessonForClass: 'Lessons.UpdateLessonForClass',
        DeleteLessonForClass: 'Lessons.DeleteLessonForClass',
        FetchPendingLessonsAttendances: 'Lessons.FetchPendingLessonsAttendances',
        FetchLessonsHours: 'Lessons.FetchLessonsHours',
        FetchRecordedVideosForClass: 'Lessons.FetchRecordedVideosForClass',
        RangeLessons: 'Lessons.RangeLessons',
        DeleteVideoForClass: 'Lessons.DeleteVideoForClass',
        CloneVideoForClass: 'Lessons.CloneVideoForClass',
        UpdateVideoOrder: 'Lessons.UpdateVideoOrder',
        UpdateVideoDescription: 'Lessons.UpdateVideoDescription',
        CalculateMaxConcurrentLicenses: 'Lessons.CalculateMaxConcurrentLicenses',
    });

    const Classrooms = Object.freeze({
        Fetch: 'Classrooms.Fetch',
        Add: 'Classrooms.Add',
        Update: 'Classrooms.Update',
    });

    const Schools = Object.freeze({
        Fetch: 'Schools.Fetch',
        Add: 'Schools.Add',
        Update: 'Schools.Update',
    });

    const Courses = Object.freeze({
        Fetch: 'Courses.Fetch',
        Add: 'Courses.Add',
        Update: 'Courses.Update',
        FetchCoursesForSchool: 'Courses.FetchCoursesForSchool',
        FetchCourseInfoById: 'Courses.FetchCourseInfoById',
        FetchCoursesRequests: 'Courses.FetchCoursesRequests',
    });

    const Periods = Object.freeze({
        Fetch: 'Periods.Fetch',
        Add: 'Periods.Add',
        Update: 'Periods.Update',
        FetchCategoryPeriodsByName: 'Periods.FetchCategoryPeriodsByName',
        FetchWithRequests: 'Periods.FetchWithRequests',
        FetchForTeacher: 'Periods.FetchForTeacher',
    });

    const Categories = Object.freeze({
        Fetch: 'Categories.Fetch',
    });

    const Areas = Object.freeze({
        Fetch: 'Areas.Fetch',
    });

    const App = Object.freeze({
        FetchLatestVersion: 'App.FetchLatestVersion',
    });

    const Attendances = Object.freeze({
        FetchClassAttendancesForUser: 'Attendances.FetchClassAttendancesForUser',
        FetchAttendancesForClassLessons: 'Attendances.FetchAttendancesForClassLessons',
        UpdateForLesson: 'Attendances.UpdateForLesson',
        FetchAttendancesForLesson: 'Attendances.FetchAttendancesForLesson',
    });

    const Notifications = Object.freeze({
        Add: 'Notifications.Add',
        AddMultiple: 'Notifications.AddMultiple',
        Update: 'Notifications.Update',
        Delete: 'Notifications.Delete',
        FetchForClass: 'Notifications.FetchForClass',
        FetchById: 'Notifications.FetchById',
        FetchInboxForUser: 'Notifications.FetchInboxForUser',
        FetchOutboxForUser: 'Notifications.FetchOutboxForUser',
        DeleteByLessonID: 'Notifications.DeleteByLessonID',
        FetchTotalUnread: 'Notifications.FetchTotalUnread',
        MarkMessageRead: 'Notifications.MarkAsRead',
        MessageIsReadByUser: 'Notifications.MessageIsReadByUser',
        FetchRecipientsForMessage: 'Notifications.FetchRecipientsForMessage',
    });

    const Offers = Object.freeze({
        FetchOffersForRequest: 'Offers.FetchOffersForRequest',
        AddOfferForRequest: 'Offers.AddOfferForRequest',
    });

    const Installments = Object.freeze({
        Fetch: 'Installments.Fetch',
        Add: 'Installments.Add',
        Update: 'Installments.Update',
        Delete: 'Installments.Delete',
    });

    const Tasks = Object.freeze({
        Fetch: 'Tasks.Fetch',
        Add: 'Tasks.Add',
        Update: 'Tasks.Update',
        Delete: 'Tasks.Delete',
    });

    const Departments = Object.freeze({
        Fetch: 'Departments.Fetch',
        Add: 'Departments.Add',
        Update: 'Departments.Update',
        Delete: 'Departments.Delete',
    });

    const Invoice = Object.freeze({
        Fetch: 'Invoice.Fetch',
        Add: 'Invoice.Add',
        Update: 'Invoice.Update',
        Delete: 'Invoice.Delete',
        FetchInvoicesByFilter: 'Invoice.FetchInvoicesByFilter',
        FetchUserInvoices: 'Invoice.FetchUserInvoices',
    });

    const InvoiceCredit = Object.freeze({
        Fetch: 'InvoiceCredit.Fetch',
        Add: 'InvoiceCredit.Add',
        Update: 'InvoiceCredit.Update',
        Delete: 'InvoiceCredit.Delete',
    });

    const Todos = Object.freeze({
        Fetch: 'Todos.Fetch',
        Add: 'Todos.Add',
        Update: 'Todos.Update',
        Delete: 'Todos.Delete',
        FetchById: 'Todos.FetchById',
        FetchTodosPerformance: 'Todos.FetchTodosPerformance',
    });

    const Chat = Object.freeze({
        NewMessage: 'Chat.NewMessage',
    });

    const JobPostings = Object.freeze({
        FetchJobPostings: 'JobPostings.FetchJobPostings',
        CreateJobPosting: 'JobPostings.CreateJobPosting',
        ReadJobPosting: 'JobPostings.ReadJobPosting',
        UpdateJobPostingApplication: 'JobPostings.UpdateJobPostingApplication',
        UpdateJobPostingApplicationComments: 'JobPostings.UpdateJobPostingApplicationComments',
        UpdateJobPosting: 'JobPostings.UpdateJobPosting',
        FetchTotalExpired: 'JobPostings.FetchTotalExpired',
        UpdateRequestsToJobPosting: 'JobPostings.UpdateRequestsToJobPosting',
        UpdateTeachersToJobPosting: 'JobPostings.UpdateTeachersToJobPosting',
    });

    const Quizzes = Object.freeze({
        FetchQuizById: 'Quiz.FetchQuizById',
        FetchQuizzes: 'Quiz.Fetch',
        FetchStudentQuizzes: 'Quiz.FetchStudentQuizzes',
        AddQuiz: 'Quiz.Add',
        UpdateQuiz: 'Quiz.Update',
        ActivateQuiz: 'Quiz.ActivateQuiz',
        CreateQuizBatch: 'Quiz.CreateQuizBatch',
        FetchStudentCoursesWithQuiz: 'Quiz.FetchStudentCoursesWithQuiz',
        FetchTeacherCoursesWithQuiz: 'Quiz.FetchTeacherCoursesWithQuiz',
        SubmitQuizAnswers: 'Quiz.SubmitQuizAnswers',
        ChangeQuizName: 'Quiz.ChangeQuizName',
        CreateTeacherQuizBatch: 'Quiz.CreateTeacherQuizBatch',
        UpdateQuizOrderNum: 'Quiz.UpdateQuizOrderNum',
        DeleteTeacherQuiz: 'Quiz.DeleteTeacherQuiz',
        CloneQuizToClass: 'Quiz.CloneQuizToClass',
    });

    const Tips = Object.freeze({
        Fetch: 'Tips.Fetch',
        Add: 'Tips.Add',
        Update: 'Tips.Update',
        Delete: 'Tips.Delete',
        FetchById: 'Tips.FetchById',
        FetchForUser: 'Tips.FetchForUser',
    });

    const Reporting = Object.freeze({
        FetchFirstAttendances: 'Reporting.FetchFirstAttendances',
        FetchSecondAttendances: 'Reporting.FetchSecondAttendances',
        FetchAttendances: 'Reporting.FetchAttendances',
        FetchLastAttendances: 'Reporting.FetchLastAttendances',
        ProposeBlocking: 'Reporting.ProposeBlocking',
        CommunicateStudents: 'Reporting.CommunicateStudents',
        RequestsExpired: 'Reporting.RequestsExpired',
        ClassesNoNextLesson: 'Reporting.ClassesNoNextLesson',
        EconomicsNoReceipts: 'Reporting.EconomicsNoReceipts',
        StudentsOnHold: 'Reporting.StudentsOnHold',
        OverlappingLessons: 'Reporting.OverlappingLessons',
        StudentsWithEconomicsIssues: 'Reporting.StudentsWithEconomicsIssues',
        StudentsWithDuesAndNoActiveClasses: 'Reporting.StudentsWithDuesAndNoActiveClasses',
        CommitReport: 'Reporting.CommitReport',
        FirstLessonOnlyClasses: 'Reporting.FirstLessonOnlyClasses',
    });

    const Settings = Object.freeze({
        Fetch: 'Settings.Fetch',
        Upsert: 'Settings.Upsert',
        Delete: 'Settings.Delete',
    });

    const RPCLogin = async (params) => {
        try {
            let result = await axios.post(`${Utils.BackendDomainURL}/login`, params);
            return { status: 'ok', data: result.data };
        } catch (error) {
            return error.response;
        }
    };

    const RPCLogout = async () => {
        try {
            console.warn('RPCLogout');
            let result = await axios.delete(`${Utils.BackendDomainURL}/logout`);
            SetStorage(StorageKeys.AccessToken, null);
            router.replace(Utils.getRoute('login'));
            return { status: 'ok', data: result.data };
        } catch (error) {
            return error.response;
        }
    };

    const RPCResetPassword = async (params) => {
        try {
            let result = await axios.post(`${Utils.BackendDomainURL}/password_reset`, params);
            return { status: 'ok', data: result.data };
        } catch (error) {
            return error.response;
        }
    };

    const RPCRequestPasswordReset = async (params) => {
        try {
            let result = await axios.post(`${Utils.BackendDomainURL}/request_password_reset`, params);
            return { status: 'ok', data: result.data };
        } catch (error) {
            return error.response;
        }
    };

    const FetchFilesInFolder = async (folderName) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + GetStorage(StorageKeys.AccessToken),
            };
            let response = await axios.get(`${Utils.BackendDomainURL}/get_files?folderName=${folderName}`, { headers });

            console.warn('response:', response);

            return response;
        } catch (error) {
            return error.response;
        }
    };

    const DeleteFileInFolder = async (folderName, fileID) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + GetStorage(StorageKeys.AccessToken),
            };
            const body = {
                folderName,
                fileID,
            };
            let response = await axios.delete(`${Utils.BackendDomainURL}/delete_file`, { headers, data: body });

            console.warn('response:', response);

            return response;
        } catch (error) {
            return error.response;
        }
    };

    const UploadFileInFolder = async (folderName, file) => {
        try {
            console.log('UploadFileInFolder', folderName, file);

            // Create an instance of FormData
            let formData = new FormData();

            // Append the file and folderName to formData
            formData.append('folderName', folderName);
            formData.append('fileName', file.name);
            formData.append('file', file);

            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + GetStorage(StorageKeys.AccessToken),
            };

            let response = await axios.post(`${Utils.BackendDomainURL}/upload_file`, formData, { headers });

            console.warn('UploadFileInFolder:', response);

            return response;
        } catch (error) {
            return error.response;
        }
    };

    const EditFileMetadata = async (folderName, fileID, metadata) => {
        try {
            console.log('EditFileMetadata', folderName, fileID, metadata);

            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + GetStorage(StorageKeys.AccessToken),
            };

            const body = {
                folderName,
                fileID,
                metadata,
            };

            let response = await axios.post(`${Utils.BackendDomainURL}/edit_file_meta`, body, { headers });

            console.warn('EditFileMetadata --- response:', response);

            return response;
        } catch (error) {
            return error.response;
        }
    };

    const GetZoomAccessToken = async (meetingNumber, role, email) => {
        try {
            console.log('zoom_access_token for  ' + meetingNumber + '  ' + role + '  ' + email);

            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + GetStorage(StorageKeys.AccessToken),
            };

            const body = {
                meetingNumber,
                role,
                email,
            };

            let response = await axios.post(`${Utils.BackendDomainURL}/zoom_access_token`, body, { headers });
            console.log('GetZoomAccessToken:', response);

            return response;
        } catch (error) {
            return error.response;
        }
    };

    const GetZoomLicences = async () => {
        try {
            console.log('GetZoomLicences');

            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + GetStorage(StorageKeys.AccessToken),
            };

            let response = await axios.get(`${Utils.BackendDomainURL}/zoom_licences`, {}, { headers });
            console.log('GetZoomLicences:', response);
            return response;
        } catch (error) {
            return error.response;
        }
    };

    const RPCApi = async (method, params) => {
        // console.log(method, params);
        let response = null;
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + GetStorage(StorageKeys.AccessToken),
            };
            response = await axios.post(
                `${Utils.BackendDomainURL}/api`,
                {
                    jsonrpc: '2.0',
                    id: nanoid(),
                    method,
                    params: params || null,
                },
                { headers }
            );
            // console.log(response);
            return { status: response.data.result.status, data: response.data };
        } catch (error) {
            console.warn('status::' + error.response?.status);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //check if history url contains "password_reset"

                if (error.response.status === 400) {
                    console.log('logout now!');
                    return RPCLogout();
                }

                // if (error.response.status === 401 && window.location.href.indexOf('password_reset') === -1) {
                //     return router.replace(Utils.getRoute('login'));
                // }

                if (error.response.status === 403) {
                    try {
                        let tokenRes = await axios.post(`${Utils.BackendDomainURL}/token`);
                        SetStorage(StorageKeys.AccessToken, tokenRes.data.accessToken);
                        return RPCApi(method, params);
                    } catch (err) {
                        console.log(err);
                        console.log('logout now - no refresh token!');
                        return RPCLogout();
                    }
                }
                return error.response;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                return error.request;
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log(error.message);
                return error.message;
            }
        }
    };

    const FetchSQLDBInfo = async () => {
        try {
            let result = await axios.get(`${Utils.BackendDomainURL}/sql_database`);
            return { status: 'ok', data: result.data };
        } catch (error) {
            return error.response;
        }
    };

    const CallCronJob = async (endpoint) => {
        try {
            let result = await axios.get(`${Utils.BackendDomainURL}/crons/${endpoint}?token=local_dev_moufa_token`);
            return { status: 'ok', data: result.data };
        } catch (error) {
            return error.response;
        }
    };

    return {
        CallCronJob,
        FetchSQLDBInfo,
        RPCApi,
        RPCLogin,
        RPCResetPassword,
        RPCRequestPasswordReset,
        RPCLogout,
        FetchFilesInFolder,
        DeleteFileInFolder,
        UploadFileInFolder,
        EditFileMetadata,
        GetZoomAccessToken,
        GetZoomLicences,
        RPCMethods: {
            Class,
            Request,
            User,
            Payments,
            Lessons,
            Classrooms,
            Schools,
            Courses,
            Periods,
            Categories,
            Areas,
            Attendances,
            Notifications,
            Offers,
            Installments,
            Tasks,
            Departments,
            App,
            Invoice,
            Todos,
            InvoiceCredit,
            Chat,
            JobPostings,
            Quizzes,
            Tips,
            Reporting,
            Settings,
        },
    };
};
